/* Login Section */

.login-section {
    display: flex;
    justify-content: space-between;
    transition: transform 0.3s ease;
    min-width: 100dvw;
}

.login-section.login-active {
    transform: translateX(0);
}

.login-section.register-active {
    width: 110dvw;
    overflow: hidden;
    transform: translateX(-15%);
}

.login-col1, .login-col2 {
    transition: width 1s ease;
}

.login-active .login-col1 {
    opacity: 1;
}

.login-active .register-section {
    display: none;
    opacity: 0;
}

.register-active .login-col1 {
    height: 0;
    visibility: 0;
    width: 0;
    opacity: 0;
}

.register-active .register-section {
    opacity: 1;
}

.login-col1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 4rem 3rem;
}
.header-login img {
    width: 7rem;
}

.login-content {
    padding-left: 2rem;
}

.login-content h1 {
    font-size: clamp(2rem, 5vw, 2.5rem);
    margin-bottom: 1rem;
}

.login-content p {
    color: rgba(0, 0, 0, 0.6);
}

.login-password {
    width: 108%;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
}

.login-password .input-container {
    width: 100%;
}

.password-toggle-icon {
    opacity: 50%;
    font-size: 1.5rem;
    vertical-align: middle;
    transition: all 0.5s ease;
}

.password-toggle-icon:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.login-button {
    margin-top: 1rem;
    padding: 1rem 0;
    border: none;
    border-radius: 10px;
    background-color: #002244;
    font-weight: 500;
    color: white;
    width: 100%;
    transition: all 0.5s ease;
}

.login-button:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.login-or {
    margin: 2rem 0;
    text-align: center;
    padding: 0 1rem;
}
.divider {
    display: flex;
    align-items: center;
    text-align: center;
    color: #888;
}
  
.divider::before,
.divider::after {
    content: '';
    flex: 1;
    border-bottom: 1.5px solid #ddd;
}

.google-log-in {
    display: flex;
    justify-content: center;
    align-items: center;
}

.remember-forgot {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.remember-forgot a {
    padding: 0.5rem 0;
    color: black;
    border-bottom: 1px solid #00224400;
    transition: all 0.5s ease;
}

.remember-forgot a:hover {
    cursor: pointer;
    color: #002244;
    border-bottom: 1px solid #002244;
}

.login-col2 {
    min-height: 100dvh;
    min-width: 55dvw;
    background-image: url('../../assets/login_background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


/* Register */
/* Add styles for the registration form */
.register-section {
    display: flex;
    flex-direction: column;
    min-width: 40dvw;
    padding: 4rem 3rem;
}

.tnc_box {
    margin: 1rem 0;
    font-size: 1rem;
    text-align: left;
}

.tnc_box label {
    font-size: 1rem !important;
    line-height: 2rem;
    color: rgba(0, 0, 0, 0.7) !important;
}

.tnc_box .myCheckbox{
    width: 2.5rem;
    transform: scale(1.5);
}

.tnc_box b{
    color: #002244;
    text-decoration: underline;
}

.fullscreen-video {
    min-width: 100dvw;
    min-height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Table size */
@media (max-width: 750px) {
    .login-col2 {
        display: none;
    }

    .register-active .login-col1 {
        display: none;
    }

    .register-section {
        min-width: 100dvw;
    }

    .login-section.register-active {
        margin-left: 5rem;
    }
}

/* Phone size */
@media (max-width:520px) {

    .login-section.register-active {
        width: 100dvw;
    }

    .login-password {
        width: 95%;
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
    }

    .login-col1 {
        padding: 0;
        max-width: 90dvw;
    }

    /* .google-log-in iframe{
        width: 200px !important;
        border-radius: 10px !important;
        border-right: 1px solid rgba(174, 174, 174, 0.545) !important;
    } */

    .login-section.register-active {
        margin-left: 1.5rem;
    }

    .register-active .register-section {
        padding:0;
        margin-left: 2rem;
        min-width: 90%;
    }
}
