.password-and-security-main {
    padding: 5rem 10rem 0 27rem;
}

.password-and-security-header {
    margin-bottom: 2rem;
}

.cur-pass, .new-pass, .confirm-pass {
    display:flex;
    justify-content: space-between;
}

.cur-pass {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.new-pass, .confirm-pass {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;
}


.password-and-security-content-box p {
    color: #3a3a3ac2;
    margin-top: 1rem;
}

.password-and-security-content-box h2 {
    margin-top: 1rem;
}

.password-and-security-input {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.password-and-security-input input {
    padding: 1rem;
    letter-spacing: 0.1rem;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    outline: none;
    width: 60%;
}

.password-and-security-input input:focus {
    border: 1px solid #003870;
}

.password-and-security-input label {
    font-size: 1rem;
    font-weight: bold;
}

.password-and-security-button {
    margin-top: 2rem;
    padding: 0.75rem 2rem;
    border-radius: 10px;
    border: none;
    background-color: #003870;
    color: #f2f2f2;
    transition: transform 0.5s ease;
    display: flex;
    justify-content: end;
    margin-left: auto;
}

.password-and-security-button:hover {
    cursor: pointer;
    transform: translateY(-5px);
}

@media (max-width:867px) {
    .password-and-security-main{
        padding:2rem 2rem 0 6rem;
    }

    .password-and-security-header {
        margin-bottom:1rem;
    }

    .password-and-security-content-box h2 {
        margin-top: 1rem;
    }
}

@media (max-width:768px) {
    .password-and-security-main{
        padding:2rem 2rem 0 2rem;
    }
}