footer {
    bottom: 0;
    background: black;
    margin-bottom: auto;
    color: white;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

footer p {
    color: rgba(255, 255, 255, 0.5);
    letter-spacing: 0.03rem;
    text-align: center;
}

.footer-header {
    margin: 3rem 0 2rem 0;
    display:flex;
}

.gradient-box {
    position: relative;
    width: min-content;
    padding: 2px;
    background: linear-gradient(45deg, #00ffff, #00ff6a, #f2ff00); /* Blue gradient */
    border-radius: 25px; /* Rounded corners */
    transition: all 0.5s ease;
}
.gradient-box::after {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: inherit;
    filter: blur(10px);
    z-index: -1;
}
.gradient-box img {
    width: 4rem;
    display: block;
    border-radius: 23px;
    background: black;
}

.gradient-box:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.meta-box {
    position: relative;
    width: min-content;
    padding: 2px;
    background: white;
    border-radius: 20px; 
    transition: all 0.5s ease;
    margin-left: 2rem;
}
.meta-box::after {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: inherit;
    filter: blur(10px);
    z-index: -1;
}
.meta-box img {
    width: 7rem;
    display: block;
    border-radius: 23px;
}

.meta-box:hover {
    cursor: pointer;
    transform: scale(1.05);
}

footer nav {
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 2rem 0 0 0;
}

footer nav a {
    color: rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    padding-bottom: 0.5rem;
    transition: all 0.5s ease;
}

footer nav a:hover {
    cursor: pointer;
    border-bottom: 1px solid rgb(255, 255, 255);
}

.social-media-div {
    font-size: 1.5rem;
}

.copyright {
    margin: 2rem 0 4rem 0;
}

@media (max-width: 550px) {
    footer nav {
        padding-left: 2rem;
    }
}