/* dashboard.css */

/* Base styles */
.main-dashboard {
    padding: 0rem 7rem 0 7rem;
    display: flex;
    gap: 3rem;
}

.title-dashboard {
    padding: 4rem 0 3rem 7rem;
}

.top_section_dashboard{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.select_date_period {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.dashboard-display-btn {
    margin-top: 3rem;
    border: none;
    color: white;
    font-weight: medium;
    font-size: 1rem;
    background: #003870;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    height: 40px;
    width: 12.5rem;
    transition: all 0.5s ease;
}

.dashboard-display-btn:hover {
    cursor: pointer;
    transform: translateY(-5px);
}


.bottom_section_dashboard {
    display: grid;
    gap: 1rem;
    grid-template-areas:
        'one one'
        'two two'
        'three three'
        'four four'
        'four four'
        'five five'
        'five five';
    padding-block: 2rem;
    margin-inline: auto;
    grid-template-columns: 250px 180px 180px 180px 180px;
    grid-template-rows: 150px 150px 250px;
}

.dashboard-item {
    padding: 2rem 2rem 4rem 2rem;
    border-radius: 15px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.dashboard-item:first-child {
    grid-area: one;
}

.dashboard-item:nth-child(2) {
    grid-area: two;
}
.dashboard-item:nth-child(3) {
    grid-area: three;
}
.dashboard-item:nth-child(4) {
    grid-area: four;
}
.dashboard-item:last-child {
    grid-area: five;
}


.dashboard-item p {
    font-weight: medium;
    letter-spacing: 0.01rem;
}
.dashboard-item h1 {
    text-align: right;
    margin-top: 2rem;
}


@media (min-width:1080px){
    .bottom_section_dashboard {
        grid-template-areas: 
        'one two two three three'
        'four four five five five'
        'four four five five five'
    }

    
}