.notifications-container {
    padding-left: 5rem;
    background: linear-gradient(to right, #E2E2E2, #C9D6FF);
    min-height: 100dvh;
}

.notifications-header {
    padding: 3rem 2rem;
    margin-bottom: 0;
}


.notifications-actions {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.notification-group {
    margin-bottom: 2rem;
}

.notification-date {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.notification-stack {
    position: relative;
    padding-top: 0.5rem;
    overflow: visible; /* Add this line */
}

.notification-bar {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px rgba(102, 95, 95, 0.151);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 1;
    overflow: visible; 
    flex-direction: row; 
}



.notification-stack:not(.expanded) .notification-bar:not(:first-child) {
    position: absolute;
    left: 0;
    right: 0;
}

.notification-stack.expanded .notification-bar {
    animation: dropDown 0.3s ease-out forwards;
}

.notification-stack .notification-bar {
    transition: all 0.3s ease-out;
}

.notification-stack.expanded .notification-bar {
    position: relative;
    opacity: 1;
    transform: translateY(0);
}

.notification-stack:not(.expanded) .notification-bar:first-child {
    transform: scale(1);
    transition: transform 0.3s ease;
}

.notification-stack:not(.expanded):hover .notification-bar:first-child {
    transform: scale(1.02);
}

.notification-bar img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
}

.notification-bar-content {
    flex: 1;
}

.notification-bar h2 {
    font-size: 1.5rem;
    margin: 0;
}

.notification-bar p {
    margin: 0;
}

.notification-bar-time {
    font-size: 0.875rem;
    color: #888;
}

.notification-counter {
    position: absolute;
    top: -0.3rem;
    right: -0.3rem;
    background-color: rgb(240, 47, 47);
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    z-index: 10000;
}


.buttons-container {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
}

.delete-button {
    background-color: #1e1c35f3;
    padding: 7px;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 2.5rem;
}

.setting-button {
    background-color: #003870;
    padding: 8px;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 3rem;
}

@keyframes dropDown {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.notification-stack.expanded .notification-bar {
    animation: bounceOut 0.3s ease-out;
}

.notification-date-group {
    border-radius: 20px 20px 0 0;
    margin-top: 0px;
    min-height: 100dvh;
    background-color: white;
    padding: 2rem;
  }

.loader-container {
    border-radius: 20px 20px 0 0;
    margin-top: 0px;
    min-height: 100dvh;
    background-color: white;
    padding: 2rem;
}
  
.notification-date-header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
}

.notification-no-message {
    display: flex;
    height: 100%;
    color: #2f2f2fa4;
    justify-content: center;
    border-radius: 20px 20px 0 0;
    min-height: 100dvh;
    background-color: white;
    padding: 2rem;
    padding-top: 5rem;
}

.mark-all-read-button {
    background-color: #1e1c35f3;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    color:white;
  }
  
.mark-all-read-button:hover {
    background-color: #1e1c35f3;
}

.notification-bar {
    display: flex;
    padding: 1rem;
    background: white;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.notification-avatar {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: #e8edff;
    color: #3a46ed;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 1rem;
}

.notification-content-wrapper {
    flex: 1;
}

.notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;
}

.notification-user {
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
}

.notification-time {
    color: #64748b;
    font-size: 0.875rem;
}

.notification-message {
    margin: 0.5rem 0;
    color: #1e293b;
}

.notification-tags {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.tag {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
}

.building-tag-noti {
    background-color: #e8edff;
    color: #3a46ed;
}

.date-tag {
    background: #dbeafe;
    color: #1e40af;
}

@media (max-width:767px) {
    .notifications-container {
        padding-left: 0;
    }

    .notifications-header {
        padding-left: 2rem;
        padding-top: 2rem;
    }

    .mark-all-read-button {
        font-size:12px ;
    }

    .notification-bar img {
        width:2rem;
        height:2rem;
    }

    .notification-bar h2 {
        font-size: 1rem;
    }

    .notification-bar p {
        font-size: 0.8rem;
    }
}

@media (max-width:525px) {
    .notifications-header h1 {
        font-size: 2rem;
    }

    .notification-date-group {
        padding:1.5rem;
    }
}

@media (max-width:400px) {
    .mark-all-read-button {
        font-size:10px ;
    }

    .notifications-header h1 {
        font-size: 1.75rem;
    }
}