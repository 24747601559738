.error_main {
    margin-top:12rem;
    text-align: center;
}

.main-details {
    padding:2rem;
}

.main-details h1 {
    font-size:5rem;
}

.main-details p {
    margin-bottom: 2rem;
}

.main-details a{
    color:white;
    border: 1px solid;
    border-radius: 15px;
    padding: 10px 20px;
    background-color: #1e1c35;
}

.big-titles img {
    object-fit: cover;
    width:20rem;
    aspect-ratio: 1/1;
    border-radius: 2px;
    overflow: hidden;
    margin: 3rem 0 ;
}
