.mobile-image-selector {
    display: flex;
    flex-direction: column;
}

.file-type-button {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.file-type-button svg {
    margin-right: 10px;
}