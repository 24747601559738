.forgot-password-main img {
    margin-top: 7rem;
    margin-left: 10%;
}

.forgot-password-main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.forgot-password-logo {
    width: 15rem;
}

.forgot-password-content {
    margin-top: 2rem;
    padding: 2rem 4rem;
    background-color: white;
    text-align: center;
}

.forgot-password-content p {
    color: #656565;
    margin-top: 1rem;
}

.forgot-password-input {
    margin-top: 2rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.forgot-password-input input{
    margin-bottom: 1rem;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    width: 40vw;
    height: 40px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.forgot-password-input button {
    font-size: 1rem;
    width: 40vw;
    height: 40px;
    border: none;
    background: #003870;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.forgot-password-input button:hover {
    transform: scale(1.01);
    box-shadow: 5px 5px 20px #6565652e;
}

.forgot-password-content span {
    text-decoration: underline;
    color:rgba(58,58,58, 0.6);
    transition: color 0.5s ease;
}

.forgot-password-content span:hover {
    cursor: pointer;
    text-decoration: underline;
    color:rgba(0,56,112);
}

@media (max-width:620px) {
    .forgot-password-input input,.forgot-password-input button {
        width:100%;
    }

    .forgot-password-content p {
        margin-top:2rem;
    }

    .forgot-password-main img {
        margin-top:5rem;
    }
}

@media (max-width:400px) {
    .forgot-password-main img {
        margin-top:1rem;
    }

    .forgot-password-content {
        padding: 1rem 2rem;
    }
}