.settings-nav {
    width:18rem;
    height: 100dvh;
    background: rgb(255, 255, 255);
    position: fixed;
    border-right: 1px solid black;
    display: flex;
    flex-direction: column;
    padding: 8dvh 0 0 2.5rem;
    transition: all 0.5s ease;
    margin-left:5rem;
    overflow-y: auto;
}


.settings-nav h1 {
    z-index: 101;
    font-size: 1.5rem;
}

.settings-nav h2 {
    font-size: 1.25rem;
    margin-top: 4dvh;
}

.settings-navitem {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 1rem;
}

.settings-navlink {
    color: rgba(58, 58, 58, 0.7);
    margin-top: 1.5rem;
    border-bottom: 2px solid transparent;
    transition: all .55s ease;
}

.settings-navbar hr {
    margin-top: 2rem;
    width: 90%;
}

.settings-navlink:hover {
    border-bottom: 2px solid rgba(58, 58, 58, 0.5)
}

.settings-active {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 0.5rem 4rem 0.5rem 0.5rem;
    border-radius: 10px;
    background-color: rgba(128, 128, 128, 0.1);
}

@media (max-width:768px) {
    .settings-nav{
        margin-left: 0;
    }

    .quick-response-nav,
    .whatsapp-template-nav {
        display: none;
    }

    .settings-navitem.chatgpt-nav {
        display: none !important; 
    }
}