.chat-area-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat-area {
    flex-direction: column-reverse;
    height: calc(100vh - 165px); /* Adjust based on your header and chat box heights */
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 1rem;
    scroll-behavior: auto !important; 
    overflow-x: auto;
  }

.chat-bubble {
    max-width: 70%;
    margin-bottom: 10px;
    clear: both;
}

.chat-bubble-right {
    float: right;
}

.chat-bubble-left {
    float: left;
}


.chat-bubble-wrapper {
    display: block;
    flex-direction: row;
    align-items: flex-end;
    max-width: 100%;
}


.chat-bubble-content {
    display: flex;
    flex-direction: column; /* Change this from row to column */
    max-width: 500px;
    padding: 1rem 15px;
    border-radius: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    line-height: 1.4;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.chat-bubble-content.text {
    flex-direction: row;
    align-items: flex-end;
}

.chat-bubble-message {
    width: 100%;
    word-break: break-word;
}

.chat-bubble-content.media {
    flex-direction: column;
}

.chat-bubble-container {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    margin-bottom: 4px;
}

.chat-bubble-avatar {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
}

.guest-avatar {
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
}

.chat-bubble-avatar-img {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    object-fit: cover;
}

.chat-bubble-content {
    border-radius: 10px;
    padding: 8px 12px;
    position: relative;
    word-wrap: break-word;
}

.chat-bubble-time {
    font-size: 11px;
    color: #8e8e8e;
    margin-top: 4px;
    padding: 0 4px;
    white-space: nowrap;  
    clear: both;
}

.chat-bubble-content-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.chat-bubble-send-by {
    font-size: 12px;
    color: #8e8e8e;
}

.chat-bubble-right .chat-bubble-time {
    text-align: end;
}

.chat-bubble-left .chat-bubble-time {
    text-align: end;
}

.chat-bubble-right .chat-bubble-content {
    background-color: #002f44;
    color: white;
    border: 1px solid rgba(227, 227, 227, 0.748);
}

.chat-bubble-left .chat-bubble-content {
    border: 1px solid rgb(202, 202, 202);
    background-color: #f0f0f0a2;
    color: #000;
}

.chat-bubble-right .chat-bubble-container {
    flex-direction: row-reverse;
}

.chat-bubble-time {
    font-size: 11px;
    color: #999;
    white-space: nowrap;
}

.chat-bubble-content.text .chat-bubble-time {
    margin-left: 10px;
}

.chat-bubble-content.media .chat-bubble-time {
    margin-top: 5px;
    align-self: flex-end;
}

.chat-bubble-content.error .chat-bubble-time {
    background-color: #ff4d4d;
    margin-top: 5px;
    align-self: flex-end;
}

.chat-bubble-image,
.chat-bubble-video {
    max-width: 350px;
    border-radius: 8px;
}

.chat-bubble-image {
    max-width: 100%;
    max-height: 300px;
    /* object-fit: cover; */
    cursor: pointer;
    transition: transform 0.2s;
}

.chat-bubble-image:hover {
    opacity: 0.8;
}

.image-preview-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
}

.image-preview-container {
    width: 70%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-preview {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    animation: zoom-in 0.3s ease-out;
}

@keyframes zoom-in {
    from {
        transform: scale(0.7);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.chat-bubble-document {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f1f3f4;
    border-radius: 8px;
    text-decoration: none;
    color: #1a73e8;
}

/* .document-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
} */

/* Custom scrollbar for .chat-area */
.chat-area::-webkit-scrollbar {
    width: 8px;
}

.chat-area::-webkit-scrollbar-track {
    background: #f0f0f0;
}

.chat-area::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.chat-area::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (max-width: 867px) {
    .chat-area {
        margin-left: 1rem;
        padding-right: 10px;
        padding-left: 5px;
        padding-top: 0px;
        overflow: auto;
        height: calc(100vh - 160px);
    }

}



.context-menu {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 8px;
    z-index: 1000;
}

.translate-button {
    display: flex;
    align-items: center;
    background-color: #f0f4f8;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-size: 14px;
    color: #333;
    text-decoration: none !important; /* Ensure no underline */
    width: 100%;
}

.translate-button:hover {
    background-color: #e1e8ed;
}

.translate-button svg {
    margin-right: 8px;
}

.translate-button span {
    font-weight: 500;
}

.chat-bubble-error {
    position: relative;
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #ff4136;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
  }
  
.chat-bubble-error span {
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.error-tooltip {
    position: absolute;
    bottom: 100%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1000;
    margin-bottom: 5px;
    pointer-events: none;
    opacity: 1;
    visibility: visible;
}

.chat-area{
    margin-left: 0;
}

.chat-box {
    margin-left: 0;
}

.chat-bubble-audio {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px 15px;
    border-radius: 10px;
    width: 250px; /* Adjust as needed */
}

.chat-bubble-audio-mobile {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 18px;
    width: 210px; /* Adjust as needed */
    height: 40px;
}

.chat-bubble-left .audio-bubble {
    background-color: #ffffff;
}

.chat-bubble-right .progress-bar {
    background-color: #00a884;
}

.chat-bubble-left .chat-bubble-document {
    background-color: #002f44;
}

.audio-bubble {
    display: flex;
    align-items: center;
    background-color: #f0f0f0a2;
    padding: 0.5rem 0.5rem 1rem 1rem;
    border-radius: 7px;
    width: 280px;
    border: 1px solid rgb(159, 159, 159);
    gap: 12px;
}

.audio-bubble-mobile {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 12px;
    width: 240px;
    height: 48px;
    padding: 4px;
}

/* Play/Pause button */
.play-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #00a884; /* WhatsApp green */
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: background-color 0.2s;
}

.play-button:hover {
    background-color: #008f72;
}

.progress-bar-container {
    width:200px;
}

.audio-control-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 10px;
    flex-shrink: 0;
}

.audio-progress-container {
    flex-grow: 1;
    height: 4px;
    background-color: #ddd;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
}

.audio-progress-bar {
    height: 100%;
    background-color: #4CAF50;
    border-radius: 2px;
    transition: width 0.1s linear;
}

.audio-time {
    font-size: 12px;
    color: #888;
    margin-left: 10px;
    flex-shrink: 0;
}

.chatbubble-reply-preview {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 13px 13px 0 0;
    padding: 8px 12px;
    font-size: 0.9em;
    margin-bottom: -1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 500px;
}

.chatbubble-reply-content {
    max-width: 500px;
    display: flex;
    align-items: center;
}

.chatbubble-reply-icon {
    margin-right: 6px;
    color: #666;
}

.chatbubble-reply-text {
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-bubble-content {
    border-radius: 0 0 13px 13px;
    margin-top: 0;
}

.chat-bubble-content.text {
    display:flex;
}

/* .chat-bubble-right .chat-bubble-content {
    background-color: #dcf8c6;
} */

/* .chat-bubble-left .chat-bubble-content {
    background-color: #ebebeb;
} */

/* For bubbles without reply preview */
.chat-bubble-wrapper:not(:has(.chatbubble-reply-preview)) .chat-bubble-content {
    border-radius: 10px;
}

/* Adjust these styles for right-aligned bubbles if needed */


.connection-lost-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.connection-lost-overlay p {
    margin-top: 10px;
    font-weight: bold;
    color: #333;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.animate-spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Add responsive design */
@media (max-width: 768px) {
    .chat-bubble {
        max-width: 85%;
    }
    .chat-bubble-content {
        max-width: 250px;
    }
    .chatbubble-reply-preview {
        max-width: 250px;
    }
}

.loading-indicator {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 5px 10px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 8px;
}

.loading-indicator span {
    font-size: 14px;
    color: #666;
}

.chat-messages-container {
    position: relative;
    height: calc(100vh - 200px); /* Adjust based on your layout */
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch; /* For better iOS scrolling */
}

/* Loading indicator styles */
.loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(90deg, transparent, #007bff, transparent);
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
}

.connection-lost-banner {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ff4444;
    color: white;
    padding: 8px;
    text-align: center;
    z-index: 1000;
}

.connection-lost-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.connection-lost-content .animate-spin {
    color: white;
}


.message-date-group {
    margin-bottom: 8px;
}
  
.date-separator {
    display: flex;
    align-items: center;
    margin: 16px 0;
    width: 100%;
}
  
.date-line {
    flex-grow: 1;
    height: 1px;
    background-color: #e0e0e0;
}
  
.date-bubble {
    background-color: #002244;
    border-radius: 16px;
    padding: 4px 12px;
    font-size: 12px;
    color: #efefef;
    margin: 0 8px;
    text-align: center;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

/* Message time and status styles */
.message-time-status {
  align-items: right;
  gap: 4px;
}

.message-status-icon {
  margin-left: 2px;
}

.message-status-sending {
  color: #9ca3af; /* gray-400 */
}

.message-status-sent {
  color: #9ca3af; /* gray-400 */
}

.message-status-delivered {
  color: #9ca3af; /* gray-400 */
}

.message-status-read {
  color: #60a5fa; /* blue-400 */
}