.header-main {
    z-index: 1000000001;
    width: 100%;
    max-width: 100%;
    top: 0;
    display: flex;
    background: white;
    padding: 0.5rem 5% 0.5rem 5%;
    letter-spacing: 0.05rem;
    position: fixed;
    border-bottom: 1px solid rgb(216, 216, 216);
    z-index: 1000;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(6.1px);
    -webkit-backdrop-filter: blur(6.1px);
    transition: all 0.50s ease;
}

.navbar-main {
    display: flex;
    align-items: center;
    margin-left: 50px;
}

.active-link {
    color: #002244;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid rgb(122, 122, 122);
}

.navbar-main a {
    font-size: 0.85rem;
    padding: 1rem 2rem;
    margin-top: 1rem;
    margin-right: 1.75rem;
    margin-left: 1rem;
    display: flex;
    color: rgba(58, 58, 58, 0.6);
    font-weight: 400;
    transition: all 0.5s ease;
}

.normal-container a:hover {
    cursor: pointer;
    color: #002244;
}

.button-container {
    display: flex;
}

.normal-container {
    display: flex;
}

.button-container a {
    border: 1px solid #002244;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    margin-right: 1.75rem;
    margin-left: 1rem;
    color: rgba(58, 58, 58, 0.8);
    font-weight: 400;
    transition: all 0.5s ease;
}

.button-container a:hover {
    transform: scale(1.01);
    background-color: #002244;
    color: white;
}

#menu-icon {
    font-size: 2.5rem;
    color: rgba(58, 58, 58, 0.8);
    cursor: pointer;
    z-index: 10001;
    display: none;
}

@media (max-width: 1250px) {
    #menu-icon {
        display: flex;
        font-size: 1.75rem;
    }

    .header-main {
        padding: 0.5rem 10% 0.5rem 10%;
        justify-content: space-between;
    }

    .navbar-main {
        padding: 2rem 1rem;
        position: fixed;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        height: 100dvh;
        justify-content: flex-start;
        border-radius: 0;
        transition: all 0.50s ease;
        margin-top: 7rem;
        visibility: hidden;
        opacity: 0;
    }

    .navbar-main a {
        color: rgba(58, 58, 58, 0.8);
        display: block;
        margin: 12px 0;
        padding: 10px 25px;
        transition: all 0.50s ease;
        font-weight: bold;
        font-size: 18px;
    }

    .navbar-main.open {
        right: 0;
        visibility: visible;
        opacity: 1;
    }

    .button-container, .normal-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: transparent;
        transition: all 0.50s ease;
    }

    .button-container a, .normal-container a {
        display: block;
        margin: 12px;
        transition: all 0.50s ease;
    }
}

@media (max-width: 940px) {
    .button-container, .normal-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: transparent;
        transition: all 0.50s ease;
    }

    .button-container a, .normal-container a {
        display: block;
        margin: 12px;
        transition: all 0.50s ease;
    }

    .navbar-main.open + .button-container {
        display: flex;
    }

    .header-main {
        padding-left: 5%;
        padding-right: 5%;
    }

    .content, .subcontent {
        padding-left: 5%;
        padding-right: 5%;
    }

    .navbar-main {
        width: 100%;
        right: -100%;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease;
    }

    .navbar-main.open {
        right: 0;
        visibility: visible;
        opacity: 1;
    }
}

@media (max-width:535px) {
    .metaicon {
        display:none;
    }
}