.settings-quick-response {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.quick-response-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.modal-quick-response {
    display: flex;
    align-items: center;
    gap: 3rem;
}

.modal-quick-response input {
    width: 100%;
    padding: 0.75rem;
    border-radius: 5px;
    border: 1px solid #2f2f2f;
}

.modal-quick-response textarea {
    padding: 0.75rem;
    width: 100%;
    border: 1px solid #2f2f2f;
    border-radius: 5px;
}


.quick-response-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}


.quick-response-modal-content {
    background: white;
    padding: 3rem;
    border-radius: 15px;
    width: 30%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.quick-response-file {
    display: flex;
    flex-direction: column;
}

.quick-response-file input {
    background-color: white;
}

.commandDeleteBtn {
    background-color: #EE4B2B !important;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 0.5rem 0.75rem;
    transition: all 0.5s ease;
}

.commandDeleteBtn:hover {
    cursor: pointer;
    transform: scale(1.01) !important;
}

.shortcut-header {
    display: flex;
    justify-content: space-between;
}

.shortcut-div {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1.5px solid black;
    padding: 1rem 1.5rem 2rem 1.5rem;
    border-radius: 15px;
    box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.2);
    max-height: 0;
    opacity: 0;
    transition: all 0.5s ease;
}

.shortcut-div.visible {
    max-height: 100dvh;
    opacity: 1;
}

.shortcut-info {
    display:flex;
    flex-direction:column;
    gap:1rem;
}

.test-modal-add-shortcut {
    border: #33333348 dashed 1.5px;
    border-radius: 15px;
    color: #33333348;
    padding: 3rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    transition: all 0.5s ease;
    margin-top: 2rem;
}

.filter-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
}

/* Loading styles */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.loading-spinner {
    width: 32px;
    height: 32px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Response card styles */
.quick-response-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 10rem;
}

.response-card {
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 1.5rem;
    transition: transform 0.5s ease;
    justify-content: space-between;
}

.response-card-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
}

.status-dot {
    width: 8px;
    height: 8px;
    background-color: #34c759;
    border-radius: 50%;
}

.command-name {
    font-size: 16px;
    font-weight: 600;
}

.response-content {
    padding-left: 16px;
    margin-bottom: 12px;
}

.text-response {
    color: #333;
    line-height: 1.5;
}

.file-response {
    padding-left: 16px;
    margin-bottom: 12px;
    color: #666;
    font-size: 14px;
}

.response-actions {
    display: flex;
    justify-content: flex-end;
}

.response-actions svg {
    width: 1.5rem;
    height: 1.5rem;

}

.response-delete-button {
    padding: 0.2rem;
    width: 2rem;
    height: 2rem;
    border-radius: 5px;
    border: 1px solid #00000000;
    font-size: 1rem;
    color: #e025008b;
    transition: all 0.5s ease;
    background-color: white;
}

.response-delete-button:hover {
    cursor: pointer;
    border: 1px solid #0000001b;
    background-color: #0000001b;
}

@media (max-width:867px) {
    .quick-response-modal-content{
        overflow-y: auto;
        width: 80%;
        height: 70vh;
    }

    .modal-quick-response{
        gap:1rem;
    }
}