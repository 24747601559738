.sh-inbox-container {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    background-color: #ffffff;
}

.sh-inbox-header {
    padding: 1rem 1.5rem;
    background-color: #ffffff;
    border-bottom: 1px solid #eaeaea;
}

.sh-header-content {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.sh-header-avatar {
    width: 3rem;
    border-radius: 50%;
    flex-shrink: 0;
}

.sh-header-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sh-header-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0;
}

.sh-chat-container {
    flex: 1;
    overflow-y: auto;
    background-image: 
        linear-gradient(rgba(255, 255, 255, 0.93), rgba(255, 255, 255, 0.93)),
        url('../../assets/background-ibx.jpg');
    background-repeat: repeat;
    max-height: 100dvh;
}


.sh-message-composer {
    display: flex;
    gap: 12px;
    padding: 16px;
    background-color: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    min-width: 100%;
}

.sh-textarea-wrapper {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
}

.sh-textarea-wrapper textarea {
    width: 100%;
    min-height: 44px;
    padding: 12px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.5;
    resize: vertical;
    transition: border-color 0.2s ease;
    outline: none;
}

.sh-textarea-wrapper textarea:focus {
    border-color: #4f46e5;
    box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
}

.sh-textarea-wrapper textarea::placeholder {
    color: #9ca3af;
}

.sh-message-input {
    width: 100%;
    resize: none;
    border: none;
    background: transparent;
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    max-height: 200px;
    outline: none;
}

.sh-message-input:focus {
    border-color: #0066cc;
}

.sh-file-preview {
    padding: 0.75rem;
    background-color: #f8f9fa;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.sh-file-preview {
    margin-bottom: 12px;
    padding: 8px;
    background-color: #f9fafb;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.sh-file-preview img {
    max-height: 100px;
    border-radius: 4px;
    object-fit: cover;
}

.sh-file-name {
    flex: 1;
    font-size: 0.875rem;
    color: #4b5563;
}

.sh-remove-file {
    padding: 4px 8px;
    background: none;
    border: none;
    color: #6b7280;
    cursor: pointer;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.sh-remove-file:hover {
    background-color: #ef4444;
    color: white;
}

.sh-attachment-options {
    position: absolute;
    right: 12px;
    bottom: 12px;
    display: flex;
    gap: 8px;
}

.sh-file-input {
    display: none;
}

.sh-attachment-button {
    padding: 6px;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.2s ease;
}

.sh-attachment-button:hover {
    background-color: #f3f4f6;
}

.sh-send-button {
    padding: 0 20px;
    background-color: #4f46e5;
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    white-space: nowrap;
}

.sh-send-button:hover {
    background-color: #4338ca;
}

.sh-send-button:disabled {
    background-color: #e5e7eb;
    cursor: not-allowed;
}

@media (max-width: 640px) {
    .sh-message-composer {
        padding: 12px;
    }
    
    .sh-textarea-wrapper textarea {
        font-size: 0.9375rem;
        padding: 10px;
    }
    
    .sh-send-button {
        padding: 0 16px;
    }
}

.sh-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 2rem;
}

.sh-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.sh-loading p {
    color: #666;
    font-size: 1rem;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .sh-message-composer {
        padding: 0.75rem;
    }

    .sh-send-button {
        padding: 0.75rem 1rem;
        min-width: 60px;
    }

    .sh-file-preview img {
        max-height: 120px;
        max-width: 240px;
    }
}
