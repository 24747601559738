/* Layout */
.chatbot-layout {
    display: flex;
    min-height: 100vh;
}
  
.chatbot-main {
    margin-left: 5rem;
    flex: 1;
    padding: 2rem;
    background-color: #f5f7fa;
}

/* Typography */
.chatbot-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1.5rem;
}

.chatbot-subtitle {
    font-size: 1.8rem;
    color: #444;
    margin-bottom: 1rem;
}

/* Form Elements */
.chatbot-form-group {
    margin-bottom: 1.5rem;
}

.chatbot-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #555;
}

#chatbotSelect{
    cursor: pointer;
}

.chatbot-input,
.chatbot-select,
.chatbot-textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}

.chatbot-textarea {
    min-height: 450px;
    resize: vertical;
}

.chatbot-select {
    cursor: not-allowed;
}

/* Buttons */
.chatbot-button {
    padding: 0.75rem 1.5rem;
    margin-bottom: 2rem;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.chatbot-button-primary {
    background-color: #002244;
    color: #fff;
}

/* Model Info Table */
.chatbot-model-info {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.chatbot-table {
    width: 100%;
    border-collapse: collapse;
}

.chatbot-table th,
.chatbot-table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.chatbot-table th {
    background-color: #f8f9fa;
    font-weight: bold;
    color: #333;
}

.chatbot-table tr:last-child td {
    border-bottom: none;
}

/* Responsive Design */
@media (max-width: 768px) {
    .chatbot-main {
        padding: 1rem;
    }

  .chatbot-title {
      font-size: 2rem;
  }

  .chatbot-subtitle {
      font-size: 1.5rem;
  }

  .chatbot-settings,
  .chatbot-model-info {
      padding: 1.5rem;
  }
}