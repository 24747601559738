.notifications-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-right: 2rem;
}


.user-notification-panel,
.secondary-accounts-panel {
    margin-top: 2rem;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 2.5rem;
    border: 1px solid black;
    margin-bottom: 3rem;
    transition: box-shadow 0.3s ease;
}

.user-notification-panel:hover,
.secondary-accounts-panel:hover {
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
}

.notification-panel-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid black;
    padding-bottom: 0.8rem;
}

.notification-category {
    font-size: 1.5rem;
    margin: 2rem 0;
    font-weight: 600;
}

.settings-task-type-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1rem;
}

.settings-task-type-option {
    display: flex;
    align-items: center;
    border: 1px solid black;
    padding: 0.75rem 0.5rem 1.5rem 0.5rem;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.settings-task-type-option:hover {
    background-color: #e0e8f0;
    transform: translateY(-2px);
}

.settings-task-type-checkbox {
    margin-right: 1rem;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.task-type-label {
    font-size: 1rem; 
    font-weight: 500;
    cursor: pointer;
}

.notification-divider {
    border: none;
    margin: 3rem 0;
}

.no-accounts-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    background-color: #f0f4f8;
    border-radius: 12px;
    font-size: 1rem;
}

.no-accounts-icon {
    font-size: 2rem;
    margin-right: 1rem;
}

.account-notification-card {
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;
}

.account-name {
    font-size: 1.3rem;
    margin-bottom: 0.8rem;
    font-weight: 600;
}

.account-detail {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.account-notification-options {
    margin-top: 1.5rem;
}

.whatsapp-option {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    font-size: 1rem;
}

.whatsapp-checkbox {
    margin-right: 1rem;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.truncate-text {
    white-space: nowrap;        /* Prevents text from wrapping to the next line */
    overflow: hidden;           /* Hides the overflowed text */
    text-overflow: ellipsis;    /* Adds ellipsis (...) at the end of the truncated text */
    flex-grow: 1;
    margin-left: 10px;
}

.switch-div-noti {
    margin-top: 0.5rem;
    display: flex;

    padding-right: 1rem;
    gap: 1rem;
    align-items: center;
}

@media (max-width: 768px) {
    .notification-settings-container {
        padding: 1.5rem;
    }

    .user-notification-panel,
    .secondary-accounts-panel {
        padding: 1.5rem;
    }

    .settings-column2 {
        margin-bottom: 5rem;
    }

    .notification-panel-title {
        font-size: 1.8rem;
    }

    .task-type-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .account-name {
        font-size: 1.4rem;
    }

    .account-detail {
        font-size: 1rem;
    }

    .task-type-label,
    .whatsapp-option {
        font-size: 1.1rem;  /* Slightly smaller on mobile */
    }

    .task-type-checkbox,
    .whatsapp-checkbox {
        width: 20px;
        height: 20px;
    }
}