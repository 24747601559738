
.contact-sales-container {
    margin: 0 5%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8rem 5rem;
    text-align: left;
}

.contact-info {
    width: 40%;
}

.contact-info h1 {
    font-size: clamp(2rem, 5vw, 4rem);
    margin-bottom: clamp(0.5rem, 2vw, 1rem);
}

.contact-info p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: rgba(0, 0, 0, 0.8);
    filter: grayscale(100%) opacity(0.5);
}

.feature-boxes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature-box {
    background-color: #002244f7;
    color: white;
    padding: 2rem;
    border-radius: 15px;
    line-height: 1.5rem;
    font-weight: 500;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease;
}

.feature-box:hover {
    cursor: pointer;
    transform: scale(1.01);
}
.contact-form {
    width: 50%;
}

.contact-form form {
    display: flex;
    flex-direction: column;
}

.input-group-row {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.input-container {
    width: 100%;
}

.submit-button {
    padding: 1rem 1.5rem;
    width: 100%;
    border: none;
    border-radius: 15px;
    background-color: #002244;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    align-self: center;
    margin-top: 3rem;
    transition: all 0.5s ease;
}

.submit-button:hover {
    cursor: pointer;
    transform: scale(1.01);
}

/* Responsive Styles */

@media (max-width: 1200px) {
    .contact-sales-container {
        margin: clamp(1rem, 5vw, 20px) clamp(1rem, 10vw, 30px);
        flex-direction: column;
        align-items: center;
    }

    .contact-info, .contact-form {
        width: 100%;
    }

    .contact-info {
        margin-bottom: clamp(2rem, 5vw, 10%);
    }

    .feature-box {
        padding: clamp(1rem, 4vw, 1.5rem);
    }

    .contact-info h1 {
        font-size: clamp(1.5rem, 5vw, 3rem);
    }

    .input-group-row {
        flex-direction: column;
        gap: clamp(0.25rem, 1vw, 0.5rem);
    }
}

@media (max-width: 768px) {
    .contact-sales-container {
        margin: 50px 50px;
        padding: 1rem 1rem;
    }

    .contact-info h1 {
        font-size: 2.5rem;
    }

    .contact-info {
        margin-bottom: 10%;
    }

    .feature-box {
        font-size: small;
    }
}

@media (max-width: 480px) {
    .contact-sales-container {
        margin: 20px 10px;
        padding: 1rem;
    }

    .contact-info {
        margin-bottom: 10%;
    }

    .contact-info h1 {
        font-size: 1.75rem;
        padding-top:7rem;
    }

    .feature-box {
        font-size: small;
    }

    .input-group h3, .input-group h4 {
        font-size: 0.875rem;
    }

    .input-group input,
    .input-group textarea,
    .input-group select {
        font-size: small;
    }

    .submit-button {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
    }
}
