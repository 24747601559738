/* Base Styles */

.faq_main {
    display: flex;
    justify-content: center;
    align-items: center;   
    padding-top: 8rem;
    padding-bottom: 2rem;
}

.faq {
    font-family: 'Inter', sans-serif;
    text-align: justify;
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    max-width: 70vw;
    margin: 20px;
}

.faq_title {
    display: flex;
    align-items: center;
    font-size: 2em;
    gap: 30px;
    margin-bottom: 30px;
    margin-top: 50px;
    margin-left: 50px;
}

.faq_item {
    border-bottom: 1px solid #ddd;
    margin-left: 50px;
    margin-right: 50px;
    transition: padding-bottom 0.5s ease;
}

.faq_item.expanded {
    padding-bottom: 20px; /* Additional padding when expanded */
}

.faq_question {
    display: flex;
    padding-top: 15px;
    padding-bottom: 20px;
    transition: background-color 0.5s ease; /* Add a transition for background color for better UX */
}

.faq_answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, padding 0.5s ease;
    margin-top: 10px;
}

.faq_answer.visible {
    max-height: 500px; /* Adjust based on the content size */
}

.faq_title svg {
    color: #1e1c35;
    font-size: 1.5em;
}

.faq_question svg {
    margin-left: auto;
    color: #1e1c35;
    font-size: 24px;
    transition: transform 0.5s ease; 
}

/* Responsive Styles */

@media (max-width: 1024px) {
    .faq {
        max-width: 90vw;
    }

    .faq_title {
        font-size: 1.75em;
        margin-left: 20px;
    }

    .faq_item {
        margin-left: 20px;
        margin-right: 20px;
    }

    .faq_question {
        font-size: 1em;
    }

    .faq_answer {
        font-size: 0.9em;
    }
}

@media (max-width: 768px) {
    .faq {
        max-width: 100vw;
    }

    .faq_title {
        font-size: 1.5em;
        margin-left: 15px;
        gap: 15px;
    }

    .faq_item {
        margin-left: 15px;
        margin-right: 15px;
    }

    .faq_question {
        font-size: 0.9em;
    }

    .faq_answer {
        font-size: 0.8em;
    }
}

@media (max-width: 480px) {

    .faq {
        max-width: 100%;
        margin: 0;
        padding: 10px;
        box-shadow: none;
    }

    .faq_title {
        font-size: 1.25em;
        margin-left: 10px;
        gap: 10px;
        margin-top: 60px;
        margin-bottom: 20px;
    }

    .faq_item {
        margin-left: 10px;
        margin-right: 10px;
    }

    .faq_question {
        font-size: 0.85em;
    }

    .faq_answer {
        font-size: 0.75em;
    }
}
