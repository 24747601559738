/* CSS for the Payment Success Page */
.payment-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f9fa;
    text-align: center;
}

.payment-success-container .icon {
    font-size: 3rem;
    color: green;
}

.payment-success-container h2 {
    font-size: 2rem;
    color: #343a40;
    margin-top: 1rem;
}

.payment-success-container p {
    font-size: 1.2rem;
    color: #6c757d;
    margin-top: 0.5rem;
}

.payment-success-container button {
    margin-top: 2rem;
    padding: 0.75rem 4rem;
    font-size: 1rem;
    color: #fff;
    background-color: #6e42c1c2;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.payment-success-container button:hover {
    background-color: #5a34a6;
}
