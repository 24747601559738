.chatflowlist-main {
    background-color: #b0d7ff1d;
    padding: 3rem 0 2rem 4rem;
    height: max-content;
}

.chatflowlist-contentbox {
    padding-left: 3rem;
    min-height: 100dvh;
}

.chatflowcard-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chatflow-delete-btn {
    padding: 0 !important;
    height: 2.5rem !important;
    width: 3rem !important;
    border-radius: 10px !important;
    background-color: red !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.chatflowlist-content {
    padding: 3rem 0 0 0;
    display: none;
}

.chatflowlist-content.chatflowlist-active {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
}

.chatflowlist-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chatflowlist-item {
    width: 25rem;
    background-color: #ffffff;
    border-radius: 25px;
    border: 1px solid black;
    transition: all 0.5s ease;
}

.chatflowlist-item.edit-active {
    width: 25rem;
}

.chatflowlist-item:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.chatflowlist-item-header {
    border-radius: 25px 25px 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom: 1px solid black;
    height: 7rem;
    width: 100%;
}

.chatflowlist-item-body {
    padding: 1.5rem 1.5rem;
    border-radius: 0 0 25px 25px;
}

.chatflowlist-item-body p {
    line-height: 1.25rem;
    color: #616161;
    font-size: 0.9rem;
}

.chatflowlist-item-body h1 {
    font-size: 1.3rem;
}

.chatflowlist-item-status {
    font-size: 0.8rem;
    padding: 0.35rem 2rem;
    width: fit-content;
    border-radius: 20px;
    color: #ffffff;
    border: none;
    background-color: #038D00;
}

.item-green {
    background-color: #038D00;
}

.item-red {
    background-color: #DA0202;
}

.chatflowlist-item-body button {
    margin-top: 1rem;
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    background-color: #002244;
    color: white;
    border: none;
    transition: all 0.5s ease;
}

.chatflowlist-item-body button:hover {
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.chatflowlist-addmore {
    display: flex;
    width: 25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #2f2f2f79;
    padding: 4rem;
    border-radius: 25px;
    border: 1px dashed rgba(0, 0, 0, 0.4);
    transition: all 0.5s ease;
}

.chatflowlist-addmore:hover {
    background-color: white;
    cursor: pointer;
    transform: scale(1.01);
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}


/* Chatflow Edit section */
.chatflow-edit {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 0;
    max-width: 26rem;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, max-width 0.5s ease, opacity 0.5s ease;
    margin-top: 0;
}

.chatflow-edit.edit-active {
    overflow-y: auto;
    padding: 1rem 1rem 0rem 1rem;
    max-height: 270px;
    max-width: 26rem;
    opacity: 1;
    margin-top: 10px;
    transition: max-height 0.5s ease, max-width 0.5s ease, opacity 0.5s ease;
}

.edit-schedule {
    display: flex;
    gap: 1rem;
}

.edit-schedule .input-container{
    width: 100%;
}

.chatflow-edit .input-container {
    margin: 10px 0 !important;
}
/* Scrollbar styles */
.chatflow-edit.edit-active::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
}

.chatflow-edit.edit-active::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.chatflow-edit.edit-active::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.chatflow-btn-div {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.chatflow-edit-btn {
    background-color: white !important;
    border: 2px solid #002244 !important;
    color: #002244 !important;
}

.input-container select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.input-container select:focus ~ label,
select:not(:placeholder-shown) ~ label {
    top: 0;
    font-size: 12px;
    color: #333;
    background-color: white;
    padding: 0 5px;
}

.input-container select::placeholder {
    color: transparent;
}

.input-icon {
    position: relative;
    display: flex;
    align-items: center;
}

.date-time-container {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.date-time-field {
    flex: 1;
    min-width: 120px;
}



@media (max-width: 768px) {
    .chatflowlist-main {
        padding: 2rem 0.5rem 0 0rem;
    }
    .chatflowlist-tab {
        flex-direction: column;
        margin-left: 0;
    }
    .chatflowlist-tab-button {
        padding: 0.5rem 1rem;
        font-size: 1rem;
    }
    .chatflowlist-content {
        padding: 2rem 0.5rem 0 5rem;
    }
    .chatflowlist-item-header {
        width: 100%;
    }
    .modal-chatcontent {
        padding: 1.5rem;
        max-height: 70vh;
    }

    .modal-inline {
        margin-bottom: 0.75rem;
    }

    .date-time-container {
        flex-direction: column;
        gap: 0.75rem;
    }

    .date-time-field {
        width: 100%;
    }
    .contact-btn-section {
        flex-direction: column;
    }
    
    .add-contact-btn, .contact-send-btn {
        width: 100%;
        margin-bottom: 10px;
        padding: 15px;
    }

    .modal-title h2 {
        margin-top:10px;
    }
    
}

@media (max-width: 480px) {
    .chatflowlist-main {
        padding: 1rem 0.5rem 0 0rem;
    }
    .chatflowlist-tab {
        flex-direction: column;
        gap: 0.5rem;
        margin-left: 0;
    }
    .chatflowlist-tab-button {
        padding: 0.5rem;
        font-size: 0.9rem;
    }
    .chatflowlist-content {
        padding: 1rem 0.5rem 0 1rem;
        flex-direction: column;
        gap: 1rem;
    }
    .chatflowlist-item-header {
        height: 5rem;
    }
    .chatflowlist-item-body {
        padding: 1rem;
    }
    .chatflowlist-item-body h1 {
        font-size: 1rem;
    }
    .modal-chatcontent {
        width: 95%;
        padding: 1rem;
    }
    .modal-inline input[type="datetime-local"] {
        width: 100%;
    }
    .chatflowlist-addmore {
        padding: 10px 10px 10px 10px;
        font-size: 10px;
    }

    .chatflowlist-addmore svg {
        padding:5%;
    }
}
