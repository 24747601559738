.navbar {
    position: fixed;
    height: 100%;
    width: 5rem;
    border-radius: 0 1.5rem 1.5rem 0px;
    background: #100f1c;
    box-shadow: 0 20px 35px rgba(0,0,0,0.25);
    text-decoration: none;
    transition: width 400ms ease;
    z-index: 1001;
}

.navbar-nav {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
}


.nav-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3.5rem;
    color: white;
    text-decoration: none;
    transition: all 0.4s ease;
}

.nav-link:hover {
    filter: grayscale(0%) opacity(1);   
}

.active {
    border-radius: 20px;
    background-color: rgba(0,0,0,0);
}

.link-text {
    cursor: pointer;
    display: none;
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
}

.navbar:hover {
    width: 20rem;
}

.navbar:hover .link-text {
    display: block;
    letter-spacing: 0.1rem;
}

#username {
    margin-left: 2rem;
    letter-spacing: 0.1rem;
}

#logo-text {
    font-weight: bold;
    letter-spacing: 0.4rem;
    font-size: 1rem;
}

/* sidenav2------------------------------------------------------ */
.sidebar {
    position: fixed;
    height: 100%;
    width: 5rem;
    border-radius: 0 1.5rem 1.5rem 0px;
    background: #100f1c;
    box-shadow: 0 20px 35px rgba(0,0,0,0.25);
    text-decoration: none;
    transition: width 400ms ease;
    z-index: 1001;
}

.sidebar:hover {
    width: 20rem;
}

.sidebar-nav {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 0.1rem;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;
}

.side-item {
    transition: all 0.5s ease;
}

.side-main, .side-item {
    margin-top: 1.5rem;
}

.side-item, .side-main, .side-setting, .side-account {
    display: flex;
    align-items: center;
    width: 100%;
    color: #ffffff;
}

.nav-item, .nav-item dash-item {
    display: flex;
    align-items: center;
    width: 100%;
    color: white;
}

.nav-item:last-child{
    margin-bottom: 1rem;
}

.nav-item, .side-main, .side-setting, .nav-item dash-item  {
    padding: 0.5rem 1rem;
}

.nav-item {
    transition: all 0.5s ease;
}

.nav-item:hover {
    background-color: #bbbbbb3c;
    border-radius: 0.5rem;
}

.sub-menu {
    display: none; 
}

.icon-second.active{
    background-color:#1e1c35; 
}

.sub-menu.active {
    display: block; 
    background-color:rgba(0,0,0,0); 
    padding-left: 4.5rem;
    margin-right:10rem;
}


.nav-link {
    align-items: center;
    width: 100%;
    height: 2rem;
    color: white;
    text-decoration: none;
    transition: filter 0.4s ease;
    padding-left: 0.8rem;
}

.nav-account {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
    filter: grayscale(100%) opacity(0.5);
    transition: all 0.4s ease;
}

.nav-link svg {
    width: 1.25rem; 
    height: 1.25rem; 
    transition: width 0.4s ease, height 0.4s ease;
}

.icon-second {
    display: none;
}

.link-text {
    display: none;
    font-size: 0.9rem;
    margin-left: 1.5rem;
    flex: 1;
}

.side-setting, .side-account {
    padding-bottom: 10px;
    padding-top: 20px;
    margin-top: 1rem;
    margin-right: 1.75rem;
    transition: border-width 20ms ease;
}

.side-setting:before, .side-account:before {
    content: "";
    position: absolute;
    top: 0;
    left: 1.75rem; 
    width: calc(100% - 2rem); 
    border-top: 2px solid #f1f1f1;
}

.sidebar:hover .link-text {
    display: block;
}

.sidebar:hover .icon-second {
    display: inline-block;
}

.side-main, .side-account, .side-setting {
    color: white; 
    font-size: 0.75rem;
    padding-left: 1.5rem; 
    filter: grayscale(100%) opacity(0.5);
}

.side-account {
    margin-top: auto;
    position: relative;
}

#logo-text {
    display: none;
    font-size: 1.25rem;
    margin-left: 10px;
    opacity: 0; 
    width: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    display: inline-block;
}

#user-icon {
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar:hover #logo-text {
    display: block; 
    opacity: 1;
    width: auto;
    margin-left: 10px;  
}

.sidebar:hover .nav-link,
.sidebar:hover .nav-account {
    filter: grayscale(0%) opacity(1);
}

.user-menu {
    position: absolute;
    top: -8rem;
    right: 0;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    animation: slideUp 0.3s ease-in-out;
}

.menu-toggle {
    display:none;
}

.mobile-nav {
    display: none;
}

.nav-link, .mobile-nav-link {
    color: rgba(255, 255, 255);
}

.nav-link .nav-icon, .mobile-nav-link .nav-icon {
    filter: grayscale(100%) opacity(0.5);
    transition: filter 0.4s ease;
}

.nav-item.active .nav-link,
.mobile-nav-link.active {
    color: white;
}

.nav-item.active .nav-link .nav-icon,
.mobile-nav-link.active .nav-icon,
.mobile-nav-link.active .mobile-link-text {
    opacity: 1;
}

.nav-link:hover,
.mobile-nav-link:hover {
    color: rgba(255, 255, 255);
}

.nav-link:hover .nav-icon,
.mobile-nav-link:hover .nav-icon,
.mobile-nav-link .mobile-link-text {
    opacity: 0.7;
}

.nav-link:hover .nav-icon {
    filter: grayscale(0%) opacity(1);
}

.nav-item.active, .mobile-nav-link.active {
    background-color: #1e1c35f3;
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.25);
    border-radius: 0px;
}

@keyframes slideUp {
    from {
        transform: translateY(10px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.user-menu ul {
    list-style: none;
}

.user-menu-item {
    padding: 1rem;
    text-align: center;
}

.user-menu-link {
    color: white;
    text-decoration: none;
    background-color: #ff0000;
    padding: 0.5rem;
    border-radius: 0.25rem;
    display: block;
    transition: background-color 0.3s ease;
}

.icon-container {
    position: relative;
    display: inline-flex;
    align-items: center;
}

.nav-link .icon-container {
    opacity: 1;
}

.notification-dot {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 8px;
    height: 8px;
    background-color: #ff0000;
    border-radius: 50%;
    opacity: 1 !important;
}

.notification-unread-dot {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: #ff4444; 
    border-radius: 50%;
    opacity: 1 !important;
  }

  .nav-link {
    position: relative; 
  }

.notification-dot.hidden {
    display: none;
}

@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        height: auto;
        bottom: 0;
        border-radius: 0;
        transition: none;
        position: fixed;
    }

    .sidebar:hover {
        width: 100%;
        background: #100f1c;
    }

    .sidebar-nav {
        display: none;
    }

    .mobile-nav {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 1rem 0;
    }

    .mobile-nav-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: rgba(255, 255, 255, 0.8);
        text-decoration: none;
        position: relative;
        overflow: hidden;
    }

    .mobile-link-text {
        font-size: 0.75rem;
        margin-top: 0.25rem;
        max-height: 0;
        opacity: 0;
        transition: max-height 0.3s ease, opacity 0.3s ease;
    }

    .mobile-nav-link .nav-icon {
        opacity: 0.5;
        transition: opacity 0.3s ease;
    }

    .mobile-nav-link.active .nav-icon {
        opacity: 1;
    }

    .mobile-nav-link.active .mobile-link-text {
        max-height: 20px; 
        opacity: 1;
    }

    #mobile-user-icon {
        border-radius: 50%;
    }

    /* Disable hover effects for mobile */
    .sidebar:hover .link-text,
    .sidebar:hover .icon-second,
    .sidebar:hover #logo-text {
        display: none;
    }

    .sidebar:hover .nav-link,
    .sidebar:hover .nav-account {
        filter: grayscale(100%) opacity(0.5);
    }

    .mobile-user-menu {
        position: absolute;
        bottom: 100%;
        right: 0;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 1002;
        min-width: 120px;
        animation: slideUp 0.3s ease-in-out;
    }

    .mobile-user-menu ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .mobile-user-menu-item {
        padding: 0.45rem 0.5rem;
    }

    .mobile-user-menu-link {
        text-decoration: none;
        text-align: center;
        color: black;
        display: block;
        padding: 0.25rem;
        transition: background-color 0.3s ease;
    }

    .mobile-user-menu-link:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .mobile-nav-link .nav-icon {
        opacity: 0.5;
    }

    .mobile-nav-link.active {
        color: white;
    }

    .mobile-nav-link.active .nav-icon {
        opacity: 1;
    }

    @keyframes slideUp {
        from {
            transform: translateY(10px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .mobile-nav-link.active .mobile-link-text {
        animation: slideUp 0.3s ease-in-out;
    }

    .mobile-nav-link.active {
        background-color:#100f1c ;
    }
}