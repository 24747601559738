.react-flow__node.selected {
    border: 1px solid #093dae90;
    border-radius: 15px;
}

.addNodeBtn {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
    background-color: white;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -0.25rem;
    left: 110%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    /* Smooth transition */
    transition: all 0.3s ease-in-out;
    z-index: 10;
}

.addNodeBtn.visible {
    opacity: 1;
    visibility: visible;
}

.addNodeBtn:hover {
    color: white;
    background-color: #002244;
}

/* startNode Section -------------------------- */
.startNode {
    font-size: 0.75rem;
    height: 4rem;
    width: 9rem;
    border: 1px solid #999999;
    border-radius: 5px;
    background: white;
    border-radius: 15px;

    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}


/* condNode Section ----------------------------- */
.conditionNode {
    font-size: 0.75rem;
    width: 15rem;
    border: 1px solid #999999;
    border-radius: 15px;
}
.conditionNode-head {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: #ADACB5;
    border-radius: 15px 15px 0 0;
    padding: 0.75rem 0 0.75rem 0 ;
    text-align: center;
}
.conditionNode-body {
    border-radius: 15px;
    background-color: #FFFFFF;
    padding: 1rem 0 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.conditionNode input {
    background-color: white;
    height: 2rem;
    width: 13rem;
    padding-left: 0.5rem;
    font-size: 0.75rem;
    border: 1px solid #9999997a;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3); 
    border-radius: 10px;
    border-image: none;
}


/* unitNode section ------------------------------- */
.unitNode {
    font-size: 0.75rem;
    width: 20rem;
    border: 1px solid #999999;
    border-radius: 15px;
    background: #FFFFFF;
    overflow: hidden; /* Add this to prevent content from overflowing */
}

.unitNode-head {
    background-color: #EAE8FF;
    padding: 1rem 0 1rem 0;
    border-radius: 15px 15px 0 0;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.unitNode-body {
    border-radius: 15px;
    background-color: #FFFFFF;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: stretch; /* Change from center to stretch */
}

.unit-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Responsive grid */
    gap: 0.5rem;
    width: 100%; /* Ensure full width */
}


.unit-card {
    display: flex;
    justify-content: space-between; /* Spread content horizontally */
    align-items: center;
    background-color: white;
    border: 1px solid #999999;
    border-radius: 10px;
    padding: 0.5rem;
    transition: all 0.5s ease;
    font-size: 0.875rem; /* Slightly larger font */
    word-break: break-word; /* Allow long words to break */
}

.add-unit-card, .add-unit-input {
    width: 100%; /* Full width */
}

.add-unit-input input {
    width: 100%;
    box-sizing: border-box; /* Include padding in width calculation */
}

.unit-card:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.cancel-active {
    font-size: 1rem;
    visibility: hidden; 
    opacity: 0; 
    color: rgb(220, 66, 66);
    transition: all 0.5s ease;
}

.unit-card:hover .cancel-active {
    visibility: visible; /* Show the icon on hover */
    opacity: 1; /* Show the icon on hover */
}

.add-unit-card {
    font-size: 1rem;
    background-color: white;
    border: 1px solid #999999;
    border-radius: 10px;
    padding: 0.5rem 1.25rem;
    transition: all 0.5s ease;
}

.add-unit-card:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.add-unit-input {
    opacity: 0;
    max-height: 0;
    padding: 0 1rem;
    overflow: hidden;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: 
        opacity 0.3s ease, 
        max-height 0.3s ease, 
        padding 0.3s ease,
        border-color 0.3s ease;
}

.add-unit-input.unit-active {
    opacity: 1;
    max-height: 500px; /* Adjust this value based on your content */
    padding: 1rem 1rem 0.5rem 1rem;
    border-color: black;
}

.add-unit-card {
    transition: display 1s ease;
}

.add-unit-input-header {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.add-unit-button {
    background-color: #002244;
    width: 100%;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 0.65rem 1rem;
    margin-bottom: 1rem;
    transition: all 0.5s ease;
}

.add-unit-button:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

/* msgNode Section -------------------------------- */
.msgNode {
    font-size: 0.75rem;
    width: 20rem;
    border: 1px solid #999999;
    border-radius: 15px;
    background: #FFFFFF;
}

.msgNode-head {
    color: #FFFFFF;
    background-color: #2D3142;
    padding: 1rem 0 1rem 0;
    border-radius: 15px 15px 0 0;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.msgNode-body {
    border-radius: 15px;
    background-color: #FFFFFF;
    padding: 1rem 0 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.msgNode textarea {
    background-color: white;
    width: 13rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
    font-size: 0.75rem;
    border: 1px solid #9999997a;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3); 
    border-radius: 10px;
    resize: none;
}

.msgNode textarea::-webkit-scrollbar {
    width: 0.5rem;
}


/* mediaNode Section ------------------------------ */
.mediaNode {
    font-size: 0.75rem;
    width: 20rem;
    border: 1px solid #999999;
    border-radius: 15px;
    background: #FFFFFF;
}

.mediaNode-head {
    background-color:#B0D7FF;
    padding: 1rem 0 1rem 0;
    border-radius: 15px 15px 0 0;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.mediaNode-body {
    border-radius: 15px;
    background-color: #FFFFFF;
    padding: 1rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.customFileUpload {
    display: inline-block;
    padding: 0.5rem 2rem 0.5rem 2rem;
    background-color: #FFFFFF;
    color: #2F2F2F;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    font-size: 0.75rem;
    border: 1px solid #9999997a;
    box-shadow: 0 5px 5px rgba(85, 85, 85, 0.1);
    transition: all 0.5s ease;
}

.customFileUpload:hover {
    background-color: #F2F2F2;
}

.mediaFile-style {
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.removeFileButton {
    background-color: #DA0202;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 10px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-align: center;
    font-size: 0.75rem; 
    border: 1px solid #9999997a;
    box-shadow: 0 5px 5px rgba(85, 85, 85, 0.1);
    transition: all 0.5s ease;
}

.removeFileButton:hover {
    background-color: #FF0000;
}


/* toDoListNode Section --------------------------- */
.todolistNode {
    font-size: 0.75rem;
    width: 15rem;
    border: 1px solid #999999;
    border-radius: 15px;
    background: #FFFFFF;
}

.todolistNode-head {
    background-color: #EAE8FF;
    padding: 1rem 0 1rem 0;
    border-radius: 15px 15px 0 0;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.todolistNode-body {
    border-radius: 15px;
    background-color: #FFFFFF;
    padding: 1rem 1rem 1rem 1rem;
}

/* templateNode Section --------------------------- */
.templateNode-body {
    padding: 0 1rem 1rem 1rem;
}

.template-item-content {
    display: flex;
    align-items: center;
    gap: 8px;
}

.template-item-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.status-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    flex-shrink: 0;
}

.status-dot.status-approved {
    background-color: #22c55e;
}

.status-dot.status-pending {
    background-color: #f59e0b;
}

.status-dot.status-rejected {
    background-color: #ef4444;
}

.template-label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.templateNode-addtemplatebtn {
    width: 100%;
    background-color: #002244;
    color:white;
    border: none;
    padding: 0.25rem;
    cursor: pointer;
    transition: all 0.5s ease;
}

.templateNode-addtemplatebtn:hover {
    background-color: #0080ff;
}


/* forwardTemplateNode Section -------------------- */
.forwardTemplateNode {
    font-size: 0.75rem;
    width: 21rem;
    border: 1px solid #999999;
    border-radius: 15px;
    background: #FFFFFF;
}

.forwardTemplateNode-body {
    padding: 0 1rem 1rem 1rem;
}

.forwardTemplateNode-grid {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.forwardTemplateNode-card {
    padding: 1rem;
    border: 1px solid black;
    border-radius: 10px;
}

.forwardTemplateNode-card p {
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
}

/* endNode Section -------------------------------- */
.endNode {
    font-size: 0.75rem;
    height: 4rem;
    width: 9rem;
    border: 1px solid #999999;
    border-radius: 5px;
    background: white;
    border-radius: 15px;

    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}


.react-flow__node {
    transition: transform 0.3s ease;
}

.react-flow__edge {
    transition: all 0.3s ease;
}

.custom-dropdown {
    position: relative;
    width: 100%;
}

.dropdown-header {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    user-select: none;
}

.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 300px;
    overflow-y: hidden;
    z-index: 9999;
}

.dropdown-search {
    width: calc(100% - 16px);
    padding: 8px;
    border: none;
    border-bottom: 1px solid #eee;
    outline: none;
}

.dropdown-items {
    max-height: 250px;
    overflow-y: auto;
}

.dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f5f5f5;
}