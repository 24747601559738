.privacy-policy {
    margin-left: 15rem;
    margin-right: 15rem;
    margin-bottom: 10rem;
    margin-top: 13rem;
    text-align: justify;
    line-height: 1.5rem;
}

.privacy-policy-header {
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    line-height: 3rem;
}

.privacy-policy h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.privacy-policy li {
    margin-top: 0.5rem;
    margin-left: 3rem;
    list-style-type: disc;
}