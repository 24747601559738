.post-detail {
    margin-top:8rem;
    width: 60%;
    border-radius: 8px;
}

.post-detail_container h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
}

.post-detail-wrapper {
    display: flex;
    width: 80%;
    margin: 0 auto;
    gap: 2rem;
    justify-content: center;
    align-items: flex-start;
    padding-left: 4rem;
}

.table-of-contents {
    font-size: small;
    padding: 1rem;
    position: fixed;
    top: 10rem; 
    width: 250px;
    margin-right: 2rem;
    left:18rem;
}

.table-of-contents h2 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.table-of-contents ul {
    list-style-type: none;
    padding: 0;
    max-height: calc(100vh - 35rem); 
    overflow-y: auto; 
}

.table-of-contents li {
    margin-bottom: 0.5rem;
    padding-left: 10px;
    border-left: 3px solid transparent;
    transition: border-left-color 0.3s ease;
}

.table-of-contents a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s ease;
    display: block;
}

.table-of-contents a:hover {
    color: #0056b3;
}

.table-of-contents li.active {
    border-left-color: #0056b3;
    background-color: white;
    border-radius: 0px;
}

.table-of-contents li.active > a {
    color: #0056b3;
    font-weight: bold;
}

.table-of-contents li.active > a::before {
    content: none;
}

.post-detail_container {
    padding:2rem;
    font-size: 1rem;
    line-height: 1.625;
    white-space: pre-wrap;
    word-break: break-word;
    word-spacing: 2px;
}

.post_content {
    font-size: 1rem;
    line-height: 1.625;
    white-space: pre-wrap;
    word-break: break-word;
}

.post-detail_header {
    display:flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.post-detail_buttons {
    display: flex;
    gap:1rem;
    align-items: center;
}


.post-detail_thumbnail {
    margin: 1rem 0;
    height: fit-content;
    max-height: 30rem;
    overflow: hidden;
}

.post-detail p, .post_content, .post-detail p2 {
    margin-bottom: 0.7rem;
    color: rgba(58, 58, 58, 0.8);
}

.post-detail p2 {
    font-style: italic;
}

.post_author-avatar img {
    display:block;
    object-fit: cover;
    width: 100%;
    border-radius: 8px;
}

.yt-vid {
    position: relative;
    margin-bottom: 1rem;
    padding-bottom: 56.25%; 
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  
  .yt-vid iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

.blog-pic3 img {
    width:50rem;
}

.blog-pic4 img {
    width:95%;
}

.blog-pic5 img {
    width:95%;
}

.blog-pic7 img {
    width:95%;
}

.post-detail h1 {
    margin-top: 3rem;
}

.post-detail h2 {
    margin-top: 1rem;
}

.pic-container img {
    width:20rem;
}

.pic-container {
    display:flex;
    background-color: #1E1C35;
    border-radius: 8px;
}

.pic-text p3, .pic-text p4 {
    display:block;
    color: white;
}

.pic-text {
    padding:2rem;
}

.post-detail hr {
    margin-top: 20px;
    margin-bottom: 20px;
}

.pic-text p3 {
    font-size:2rem;
    letter-spacing: 0.05rem;
}

.pic-text button {
    padding:0.8rem;
    background-color: blue;
    border-radius: 20px;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    margin-top: 30px;
    color: white;
}

.point-form li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
    color: rgba(58, 58, 58, 0.7);
}

.point-form li a {
    color: blue;
    text-decoration: underline;
}

/* bullet point form */
.point-form li::before { 
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 6px;
    height: 6px;
    background-color: #000;
    border-radius: 50%;
}

.whatsapp-comparison-table {
  margin: 2rem 0;
  overflow-x: auto;
}

.whatsapp-comparison-table table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.whatsapp-comparison-table th,
.whatsapp-comparison-table td {
  border: 1px solid #e0e0e0;
  padding: 0.75rem;
  text-align: left;
}

.whatsapp-comparison-table th:first-child,
.whatsapp-comparison-table td:first-child {
  width: 20%; 
}

.whatsapp-comparison-table td:first-child {
    font-weight: bold;
}

.whatsapp-comparison-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.whatsapp-comparison-table .table-footnote {
  margin-top: 1rem;
  font-size: 0.8rem;
  font-style: italic;
  color: #666;
}

.post-detail p3 {
    font-weight: bold;
    font-size: 1.15rem;
}

.post-detail_img img {
    width:80%;
    margin-bottom: 20px;
}


@media (max-width:1750px) {
    .post-detail {
        width: 70%;
        margin-left: 6rem;
    }

    .table-of-contents {
        left: 13rem;
    }
}

@media (max-width:1620px) {
    .post-detail {
        width: 70%;
        margin-left: 10rem;
    }

    .table-of-contents {
        left: 10rem;
    }
}

@media (max-width:1200px) {
    .post-detail {
        width: 100%;
        margin-left:0;
        padding-left:0rem;
    }

    .post-detail-wrapper {
        padding-left: 0rem;
    }

    .table-of-contents {
        display:none;
    }
}

@media (max-width:400px) {
    .post-detail-wrapper {
        width:100%;
    }

    .post-detail {
        background-color: transparent;
    }
}