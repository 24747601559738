.help-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #1e1c35;
    color: white;
    border: none;
    border-radius: 25px;
    width: 100px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: all 0.5s ease;
}

.help-button:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
    background-color: #1e1c35;
}

.help-button span {
    margin-left: 8px;
    font-weight: bold;
    font-size: 1rem;
}


/* Actual help modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
}

.modal-content {
    width: 50rem;
    height: 40rem;
    background: #ffffff;
    padding: 3rem;
    border-radius: 25px;
    box-shadow: 0px 10px 20px -4px rgba(0,0,0,0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.modal-conhead {
    align-items: center;
    margin-bottom: 1rem;
    display: flex;
    align-items:  center;
    gap: 6rem;
}

.modal-conhead h2 {
    text-align: center;
    font-size: 2rem;
}

.modal-conhead p {
    text-align: center;
    font-size: 1rem;
    margin-top: 20px;
    filter: grayscale(100%) opacity(0.5);
}

.modal-back-btn {
    background: none;
    border: none;
    margin-top:5px;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.5s ease;
}

.modal-back-btn:hover {
    transform: scale(1.1);
}

/* input style */
.modal-content .input-container {
    position: relative;
    margin: 0;
}

.input-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.input-container textarea {
    resize: none;
    width: 100%;
    min-height: 10rem;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.input-container label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #999;
    pointer-events: none;
    transition: 0.3s ease all;
}

.input-container input:focus ~ label,
input:not(:placeholder-shown) ~ label {
    top: 0;
    font-size: 12px;
    color: #333;
    background-color: white;
    padding: 0 5px;
}

.input-container input::placeholder {
    color: transparent;
}

.input-container textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label {
    top: 0;
    font-size: 12px;
    color: #333;
    background-color: white;
    padding: 0 5px;
}

.input-container textarea::placeholder {
    color: transparent;
}

/* Form arrangement */
.contact-name {
    width: 100%;
    display: flex;
    gap: 1rem;
}

.contact-name .input-container {
    width: 100%;
}

.contact-name .input-container input {
    width: 100%;
}

/* Modal specific styles for Contact Sales */
.contact-sales-container.modal {
    margin:0px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    text-align: left;
    overflow: hidden;
}
.contact-sales-content.modal {
    display: flex;
    justify-content: space-between;
}

.contact-sales-container.modal .contact-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.contact-sales-container.modal .contact-form button{
    border-radius: 15px;
    padding: 1rem 2rem;
    border: none;
    color: white;
    font-size: 1rem;
    background-color: #002244;
    transition: all 0.5s ease;
}

.contact-sales-container.modal .contact-form button:hover{
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.2);
}

@media (max-width:768px) {
    .help-button {
        display:none;
    }
}