.plan-section {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
}

#plan-free-card,
#plan-standard-card,
#plan-premium-card {
    flex: 1;
    padding: 2rem;
    margin: 0 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#plan-standard-card .tag {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: gold;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 50%;
}

.plan-price {
    font-size: 2rem;
    font-weight: bold;
}

.plan-limit-text {
    margin: 0.5rem 0;
}

.buy-button {
    background-color: #007bff;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buy-button:hover {
    background-color: #0056b3;
}


.plans-and-billing-header {
    text-align: center;
    margin-bottom: 2rem;
}

.plans-and-billing-nav {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.plans-and-billing-navitem {
    margin: 0 1rem;
    cursor: pointer;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.plans-and-billing-navitem:hover {
    background-color: #f0f0f0;
}

.plans-and-billing-content {
    display: flex;
    justify-content: center;
}

/* Pricing section */
.pricing-section {
    z-index: 101;
    margin-bottom: 5rem;
}

.pricing-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gradient-text {
    background: rgb(131,58,180);
    background-image: linear-gradient(90deg, #3aa9ff, #00ff00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pricing-header p {
    font-size: clamp(0.75rem, 1.5vw, 1rem);
    font-weight: 300;
    color: rgba(58, 58, 58, 0.8);
    letter-spacing: 0.05rem;
    line-height: 2rem;
    text-align: center;
}

.pricing-header h1 {
    width: 50%;
    font-size:clamp(1.5rem, 5vw, 2rem);
    padding: 1rem 0rem 2rem 0rem;
    line-height: 3rem;
    text-align: center;

    background-image: linear-gradient(90deg, #3aa9ff, #00ff00);
    background-repeat: no-repeat;
    background-position: 0 80%;
    background-size: 100% 2px;
}

.pricing-body-setting {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.pricing-body-setting > div {
    padding: 3rem 2rem;
    min-width: 24rem;
    background-color: white;
    border-radius: 20px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: all 0.5s ease;
    width: 22%;
}

.pricing-body-setting > div:hover {
    transform: scale(1.03);
    box-shadow: 0 0 4px rgba(0,0,0, 0.3)
}

.pricing-content-header p{
    font-weight: 500;
    font-size: 1rem;
    color:#00000073;
}

/* Special pricing */
.pricing-medium {
    background-color: #001223 !important;
    padding: 2.3rem 2rem !important;
    color: white !important;
}
.pricing-medium h1 {
    color: rgba(255, 255, 255, 0.5) !important;
}
.pricing-medium span {
    color: white !important;
}
.pricing-medium .tier p {
    color: rgba(255, 255, 255, 0.6) !important;
}
.pricing-medium button {
    background-color: white !important;
    color: black !important;   
}
.pricing-medium input:checked + .slider-btn {
    background-color: #ffffff60;
}

.tier {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.popular-tag {
    font-size: 0.75rem;
    border-radius: 15px;
    padding: 0.5rem;
    background: linear-gradient(45deg, #20f8ff, #00ff55);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;

}
.popular-tag::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 15px;
    border: 1px solid transparent;
    background: linear-gradient(45deg, #20f8ff, #00ff55) border-box;
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}


/* Pricing content */
.price-setting {
    font-size: 2rem;
    color: black;
    font-weight: bold;
}

.pricing-content-header h1 {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 1rem;
    color: #0000006e;
}

.pricing-content-header button {
    border: none;
    border-radius: 15px;
    background-color: #002244;
    color: white;
    font-weight: 500;
    padding: 0.75rem;
    margin-top: 2rem;
    width: 100%;
    transition: all 0.5s ease;
}

.pricing-content-header button:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.pricing-content-selection {
    margin-top: 1rem;
}
.pricing-content-selection p{
    font-weight: 500;
    font-size: 0.9rem !important;
}

.tick-icon {
    vertical-align: middle;
    font-size: 1.75rem;
    margin-right: 1rem;
}

.pricing-content-body {
    font-weight: 500;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

@media (max-width:1485px) {
    .price-setting {
        font-size: 1.5rem;
    }

    .pricing-body-setting > div {
        width:30%;
        justify-content: center;
    }
}

@media (max-width:1345px) {
    .pricing-body-setting > div {
        width:40%;
    }
}

@media (max-width:1150px){
    .pricing-body-setting {
        display: block;
        justify-content: center;
    }

    .pricing-body-setting > div {
        width:100%;
        margin-bottom: 1rem;
    }
}

@media (max-width:768px) {
    .plans-and-billing-content {
        display:block;
        padding-left: 1rem;
    }
}