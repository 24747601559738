.chatroom-list-container {
    /* max-height: 70dvh; */
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

/* TEST */
.chatroom-list-container {
    contain: content;
    will-change: transform;
}

.chatroom-item {
    content-visibility: auto;
    contain-intrinsic-size: 0 80px;
}

.skeleton-item {
    display: flex;
    gap: 16px;
    padding: 16px;
    animation: pulse 1.5s infinite ease-in-out;
}

.skeleton-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #eee;
}

.skeleton-line {
    height: 12px;
    background: #eee;
    border-radius: 4px;
    margin: 8px 0;
}

@keyframes pulse {
    0% { opacity: 0.6; }
    50% { opacity: 1; }
    100% { opacity: 0.6; }
}

/* TEST */
.chatroom-list {
    position: relative;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #69656511;
    transition: all 0.5s ease;
}

.inbox-item-name-time-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.popup-menu {
    position: fixed;
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    z-index: 1000; /* Ensure it appears above other elements */
}

.popup-menu-button {
    background: #ff4d4d; /* Red color for delete action */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s;
}

.popup-menu-button:hover {
    background: #e60000; /* Darker red on hover */
}

.inbox-item {
    padding: 5px 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.inbox-item-pic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    margin-left: 7px;
    margin-right: 7px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
}

.inbox-item-pic img {
    width: 30px;
    height: 30px;
}

.chatroom-list:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05); /* Change this to your desired color */
    border-radius: 4px; /* Change this to your desired radius */
}

.chatroom-selected {
    background-color: rgba(0, 152, 179, 0.125);
    border-left: 4px solid #003871;
    border-radius: 4px;
}

.building-tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 4px;
}

.building-tag {
    background-color: #003264; 
    color: white;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 12px;
}

.inbox-item-name {
    display: flex;
    flex-direction:column;
    justify-content: center;
    font-size: 15px;
    flex: 1;
    margin-right: 5px;
}

.inbox-item-name-and-date{
    margin-top: 5px;
    display: flex;
    justify-content: space-between;

}

.inbox-item-name-and-date h3{
    font-size: 0.9rem;
    max-width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.inbox-item-time {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.inbox-item-time p {
    font-size: 11px;
    align-self: flex-end; /* Aligns the timestamp to the right */
    margin-right: 10px; 
}

.inbox-item-last-message {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.inbox-item-last-message-timestamp {
    margin-top: auto;
    font-size: 11px;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);

}

.inbox-item-last-message-text {
    max-width: 140px; 
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
    font-size: 13px;
}

.chat-area-section-time {
    font-size: 10px;
    text-align: right;
}

.dropdown-menu {
    padding: 10px;
    background-color: rgba(100, 98, 98, 0.678);
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.705);
}
.dropdown-menu :hover {
    cursor: pointer;
    background-color: rgba(100, 98, 98, 0.678);
    border-radius: 10px;
    color: rgba(255, 255, 255, 0.397);
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

.status-tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top:5px;
}

.status-tag {
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
}

.label-tag {
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.75rem;
    margin-right: 4px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.unread-badge {
    background-color: #4CAF50;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    margin-right: 8px;
    display: inline-block;
    min-width: 20px;
    text-align: center;
    /* Add animations */
    animation: badgePop 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.2s ease, opacity 0.2s ease;
}

/* Entrance animation for the badge */
@keyframes badgePop {
    0% {
        transform: scale(0) rotate(-45deg);
        opacity: 0;
    }
    70% {
        transform: scale(1.2) rotate(5deg);
    }
    100% {
        transform: scale(1) rotate(0);
        opacity: 1;
    }
}

/* Optional: Add a nice exit animation when the badge disappears */
.unread-badge.hiding {
    animation: badgeOut 0.2s ease forwards;
}

@keyframes badgeOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}

.loading-more {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    opacity: 0.7;
}

.loading-more .loader {
    width: 30px;
    height: 30px;
    border-width: 3px;
}


.context-menu-item {
    border: none;
    border-radius: 5px;
    background-color: #002244;
    color: white;
    padding: 0.5rem 1rem;
}