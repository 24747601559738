.inbox-main {
    max-height: 100dvh;
    overflow-y: hidden;
    margin: 0;    
}

.inbox-main-div {
    display: flex;
    max-height: 100dvh;
}

.inbox-column1 {
    max-height: 100dvh;
    padding-left: 5.5rem;
    min-width: 23rem;
    padding-right:5px;
    border: 1px solid #a8a8a87b;
    border-bottom: none;
    border-radius: 0px;
    left: 0;
    min-height: 100dvh;
    background-color: white;
    width:26%;
}

.inbox-column3 {
    max-height: 100dvh;
    width: 18%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 1px solid #a8a8a87b;
    border-radius: 0px;
    background-color: white;
    transition: all 0.3s ease;
    overflow-y: auto;
}

.main-column3 {
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
    align-items: center;
    border-bottom: 1px solid rgba(157, 157, 157, 0.4);
}

.inbox-column3 .inbox-item-pic {
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    gap:1rem;
    border-bottom: 2px solid #69656511;
    padding-bottom: 1rem;
}

.inbox-column3 .inbox-item-pic img {
    width: 70px;
    height: 70px;
}

.inbox-column3 .inbox-item-pic b {
    margin-top: 5px;
    line-height: 1.5rem;
}

.inbox-column3.hidden {
    display: none;
}

.inbox-column3.visible {
    display: block;
}

.inbox-header-button {
    display:flex;
}

.data-tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 4px;
}

.data-tag {
    background-color: #f0f0f0;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 15px;
    color: #333;
}

.three-dot-menu {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    transition: color 0.2s ease;
}


.three-dot-menu:hover {
    color: #333;
}

.column3-pfp {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    border: 2px solid rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    margin: 1rem 0;
}

.personal-data p {
    color:#000000c6;
    display: flex;
    margin-bottom: 10px;
    align-items: flex-start;
    font-size:1rem;
}

.personal-data svg {
    flex-shrink: 0;
    width: 24px;
    margin-top: 7px;
}

.personal-data span {
    color: black;
}

.action-buttons {
    display: flex;
    margin-top:2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #76757569;
}

.edit-contact-btn {
    width: 100%;
    padding: 0.6rem 0;
    font-size: 1rem;
    border-radius: 8px;
    color: white;
    border: none;
    background-color: #002244;
    transition: all 0.5s ease;
}

.edit-contact-btn:hover {
    cursor: pointer;
}

.delete-btn {
    width: 100%;
    font-size: 1rem;
    border-radius: 8px;
    margin-left:1rem;
    border: none;
    color: white;
    background-color: rgb(255, 0, 0);
    transition: all 0.5s ease;
}

.delete-btn:hover {
    cursor: pointer;
}

.tag-section {
    margin-top: 1rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.tag-container {
    margin-top: 1rem;
    display:flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.client-tag {
    min-width: 5rem;
    width: 48%;
    text-align: center;
    padding: 0.5rem;
    border-radius: 16px;
    border: 1px solid #004a94;
}

.add-tag-button {
    width: 100%;
    padding: 0.75rem 0;
    border: 1px solid black;  
    border-radius: 16px;
    transition: all 0.5s ease;
}

.add-tag-button:hover {
    cursor: pointer;
    color: white;
    background-color: #002244;
}

.personal-task-section {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 25dvh;
    overflow-y: scroll;
}

.personal-task {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    background-color: white;
    border-radius: 8px;
    border: 1px solid black;
    padding: 10px;
    touch-action: none;
    transition: all 0.5s ease;
}

.personal-task:hover {
    cursor: pointer;
}

.personal-task-btn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
}

.add-task-btn {
    display: flex;
    margin-top: 1.5rem;
}

.add-task-btn button {
    border: 1px solid black;
    border-radius: 10px;
    background-color: white;
    padding: 0.5rem;
    width: 100%;
}

.add-task-btn button:hover {
    cursor: pointer;
}

.inbox-settings-chatbot-btn {
    cursor: pointer;
}

.inbox-header {
    padding: 1.5rem 0;
    display: flex;
    justify-content: space-between;
}

.channel-select {
    position: relative;
    min-width: 150px;
}

.channel-select select {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: white;
    font-size: 0.9rem;
    cursor: pointer;
    width: 100%;
}

.channel-select select option[disabled] {
    color: #999;
    cursor: not-allowed;
    background-color: #f5f5f5;
}

.channel-select select option[disabled]:hover {
    cursor: not-allowed;
}

.main-inbox-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
.main-inbox-name {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.main-inbox-header h3 {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 700px;
}

.inbox-settings-btn:hover {
    cursor: pointer;
}

.toggle-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
}
  
.toggle-switch {
    width: 56px;
    height: 28px;
    background-color: #e5e5e5;
    border-radius: 999px;
    position: relative;
    transition: background-color 0.4s ease-in-out;
}
  
.toggle-switch.active {
    background-color: #22c55e;
}
  
.toggle-slider {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.4s ease-in-out;
}

.toggle-switch.active .toggle-slider {
    transform: translateX(28px);
}

.toggle-robot {
    position: absolute;
    top: 50%;
    width: 16px;
    height: 16px;
    transition: all 0.4s ease-in-out;
}

.toggle-switch:not(.active) .toggle-robot {
    right: 8px;
    transform: translateY(-50%);
    color: #4b5563;
}

.toggle-switch.active .toggle-robot {
    left: 8px;
    transform: translateY(-50%);
    color: white;
}

.search-bar {
    justify-content: space-between;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.search-bar input{
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    width: 85%;
    height: 35px;
    border-radius: 5px;
}

.search-bar .input-container {
    margin:0;
    width: 85%;
}

/* Filter Styles */
.filter-container {
    width: 100%;
    max-width: 1200px;
    padding: 16px 0 0 0;
}

.filter-wrapper {
    display: flex;
    gap: 0.5rem;
}


.filter-group {
    margin-bottom: 10px;
}

.filter-group:last-child {
    margin-bottom: 0;
}

.filter-label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #374151;
    margin-bottom: 8px;
}

.filter-select-wrapper {
    width: 100%;
}

/* React-Select Custom Styles */
.filter-select > div:first-of-type {
    min-height: 42px;
    border-radius: 8px;
    border-color: #e5e7eb;
}

.filter-select > div:first-of-type:hover {
    border-color: #d1d5db;
}

/* Disabled state */
.filter-select > div[class*="-control"][class*="-is-disabled"] {
    background-color: #f9fafb;
}

/* Multi-value chips */
.filter-select [class*="-multiValue"] {
    background-color: #f3f4f6;
    border-radius: 6px;
}

.filter-select [class*="-multiValueLabel"] {
    font-size: 14px;
    padding: 2px 6px;
}

.filter-select [class*="-multiValueRemove"]:hover {
    background-color: #e5e7eb;
    color: #4b5563;
}

/* Placeholder */
.filter-select [class*="-placeholder"] {
    color: #9ca3af;
}

/* Style for react-select components */
.filter-select {
    min-width: 140px; /* Adjust width as needed */
}

.filter-select .select__control {
    min-height: 28px;
    height: 28px;
    border-radius: 15px;
    border: 1px solid #e0e0e0;
    box-shadow: none;
    background: #f5f5f5;
}

.filter-select .select__value-container {
    padding: 0 8px;
}

.filter-select .select__indicators {
    height: 28px;
}

.filter-select .select__indicator {
    padding: 4px;
}

.filter-select .select__single-value {
    font-size: 13px;
}

.filter-select .select__placeholder {
    font-size: 13px;
}

.inbox-list {
    height: calc(100% - 200px);
    scrollbar-width: thin;
    overflow-y: auto;
}

.inbox-list::-webkit-scrollbar {
    width: 6px;
}

.inbox-list::-webkit-scrollbar-track {
    background: transparent;
}

.inbox-list::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
}

.inbox-item {
    padding: 5px 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.inbox-item-pic {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.inbox-item:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Change this to your desired color */
    border-radius: 10px; /* Change this to your desired radius */
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2); /* Change this to your desired shadow */
}

.inbox-item-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inbox-item-time p {
    font-size: 12px;
}

.inbox-column2 {
    background-image: 
        linear-gradient(rgba(255, 255, 255, 0.93), rgba(255, 255, 255, 0.93)),
        url('../../assets/background-ibx.jpg');
    min-height: 100dvh;
    max-height: 100dvh;
    flex: 1;
    display: flex;
    flex-direction: column; 
    position: relative;
    width: 40%;
}

.inbox-header2 {
    background-color: white;
    padding: 1.5rem 1rem 1.5rem 1rem;
    border-bottom: 1px solid #3e3e3e4e;
    position: relative;
    z-index: 2;
}

.chatbot-active-bar {
    background-color: #66bb6a;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    color: white;
    text-align: center;
    font-weight: 500;
    top: 100%;
    left: 0;
    right: 0;
    /* z-index: 100000; */
    padding: 5px 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.chatbot-active-bar.chatbot-is-active {
    display: none; /* Show when active */
    max-height: 40px;
}

.chatbot-active-bar .icon-wrapper {
    align-items: center;
    margin-right: 5px;
}

.chatbot-active-bar svg {
    padding-top: 3px;
}

.inbox-add-user-image-icon {
    width: 25px;
    height: 25px;
}
  
.inbox-add-contact {
    transition: all 0.3s;
}
.inbox-add-contact:hover {
    cursor:pointer;
    transform: scale(1.05);
}

.add-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.modal-addcontact h2{
    margin-left:20px;
    margin-bottom:30px;
}

.close-contact {
    background: none;
    border: none;
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 1.5rem;
    margin-top:1rem;
    cursor: pointer;
}

.add-field input{
    width: 100%;
    height: 3rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: small;
}

.add-field h4 {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 1rem;
}

.modal-addcontact {
    background: #F2F2F2;
    padding: 2rem;
    border-radius: 15px;
    width: 500px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
}

.add-button {
    background-color: #003870;
    color: white;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 10px;
    width: 100%;
    margin-top: 2rem;
    cursor: pointer;
}

.chat-box {
    position: relative; /* Add this line */
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.8rem 1rem;
    align-items: center;
    background-color: white;
    border-top: 1px solid #69656571;
    box-sizing: border-box;
    display: block;
    padding-top: 0;
}


.chat-box i {
    font-size: 20px;
    padding: 10px 20px;
}

.chat-box form {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

.chat-box textarea {
    font-size: 1rem;
    border: none;
    padding: 1rem 0 0 1rem;
    margin-bottom: 1rem;
    width: 100%;
    border-radius: 10px;
    flex-grow: 1;
    resize: none;
}

.chat-box textarea:focus {
    outline: none;
    border: none;
}

.chat-box-send-icon-symbol {
    margin: 0 10px;
    width: 20px;
}

.attachment-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top:10px;
}

.chat-icon {
    font-size: 1.2rem;
    margin: 0 0.5rem;
}

.inbox-send-button .chat-box-send-icon-symbol-button {
    border: 1px solid #002244;
    padding:8px 15px;
    border-radius: 8px;
    background-color: #002244;
    color:white;
    margin-right: 0;
}


.chat-box-send-icon-symbol-button {
    background-color: white;
    display: flex;
    cursor: pointer;
    border: none;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    margin-right: 5px;
}

.chat-box-send-icon-symbol-button .chat-icon {
    transition: transform 0.3s ease;
}

.chat-box-send-icon-symbol-button.active .chat-icon {
    transform: rotate(45deg);
}

.dropdown-menu-chat-box {
    position: absolute; /* Change to absolute positioning */
    bottom: 98%; /* Position above the button */
    left: 0px; /* Align with the left edge of the button */
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    margin-bottom: 5px; /* Add some space between dropdown and button */
    min-width: 120px; /* Ensure the dropdown has a minimum width */
}

.dropdown-translate-menu-chat-box {
    position: absolute; /* Change to absolute positioning */
    bottom: 98%; /* Position above the button */
    left: 20px; /* Align with the left edge of the button */
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    margin-bottom: 5px; /* Add some space between dropdown and button */
    min-width: 120px;
}

.dropdown-menu-chat-box ul,
.dropdown-translate-menu-chat-box ul {
    list-style: none; /* Remove default list styling */
    margin: 0; /* Remove default margin */
    padding: 5px 0; /* Padding inside the dropdown */
}

.dropdown-menu-chat-box li,
.dropdown-translate-menu-chat-box li {
    display: flex; 
    align-items: center; 
    padding: 8px 15px;
    cursor: pointer;
    font-size: 15px;
}

.dropdown-menu-chat-box li:hover,
.dropdown-translate-menu-chat-box li:hover {
    background-color: #f0f0f0; 
}

.dropdown-menu-chat-box li svg,
.dropdown-translate-menu-chat-box li svg {
    margin-right: 10px; 
    font-size: 18px;
}

.tabs-container {
    margin-bottom: 1rem;
}

.tab-button {
    border:none;
    background-color: white;
    padding:10px 20px;
    transition: all 0.5s ease;
}

.tab-button:hover {
    cursor: pointer;
    background-color: #ebebeb63;
}

.tab-button.active {
    background-color: #ebebeb;
    border-radius: 0;
}

.command-menu {
    position: absolute; /* Change to absolute positioning */
    bottom: 80%; /* Position above the button */
    left: 60px; /* Align with the left edge of the button */
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    min-width: 300px; /* Ensure the dropdown has a minimum width */
}

.command-item {
    padding: 0.5rem 1rem;
    transition: all 0.5s ease;
    border-bottom: #ddd solid 1px;
}

.command-item:hover {
    cursor: pointer;
    background-color: #f0f0f0; /* Background color on hover */
}

.error-message {
    color: red;
    font-size: 0.8em;
    margin-top: 5px;
    display: block;
}

.media-preview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.3); 
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000000;
    border-radius: 0; 
    padding: 0;
}

.send-media-container {
    display: flex;
    justify-content: end;
    width: 100%;
}

.media-preview img,
.media-preview video {
    max-width: 70%;
    max-height: 70%;
    object-fit: contain;
}

.caption-input {
    margin-top:20px;
    padding-bottom: 20px;
}

.caption-input textarea {
    font-size: 1rem;
    border: none;
    padding: 0.8rem 0.8rem;
    padding-bottom: 0;
    margin-bottom: 0;
    width: 100%;
    border-radius: 6px;
    flex-grow: 1;
    resize: none;
}

.media-preview div {
    /* For document preview */
    display: flex;
    align-items: center;
    border-radius: 8px;
    width: 30%;
}

.media-preview button {
    background-color: #002244;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
}

/* .media-preview button:hover {
    background-color: #008c6f;
} */

.media-preview button:first-child {
    background-color: red;
}

/* .media-preview button:first-child:hover {
    background-color: #2f3438;
} */

.inbox-no-wabaid {
    display: flex;
    justify-content: center ;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.chatflow-sendbtn:disabled {
    cursor: not-allowed;
    background-color: #3b4957 !important;
}


/* Send template section */
.template-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000001;
}

.template-modal-container {
    width:60%;
    position: absolute;
    background-color: white;
    min-height: 30dvh;
    border-radius: 25px;
    padding: 3rem 0rem 0rem 3rem;
    box-shadow: 0px 10px 20px -4px rgba(0,0,0,0.2);
}

.template-modal-content {
    max-height: 80dvh;
    max-width: 60dvw;
    padding: 0 2rem 3rem 0rem;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}

.template-modal-content button {
    margin-top: 1rem;
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    background-color: #002244;
    color: white;
    border: none;
    transition: all 0.5s ease;
}

.template-modal-selection {
    position: relative;
    z-index: 10000000; /* Ensure dropdown appears above other content */
    width: calc(100% - 2rem); /* Account for right padding */
}

.template-modal-content button:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.template-modal-content button:disabled {
    cursor: not-allowed;
    background-color: #3b4957 !important;
}


.template-modal-header {
    display: flex;
    gap: 1rem;
}


/* To-do list section */
.todolist-modal-content {
    max-height: 80dvh;
    max-width: 60dvw;
    padding: 0 2rem 3rem 0rem;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.todolist-modal-content button {
    margin-top: 1rem;
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    background-color: #002244;
    color: white;
    border: none;
    transition: all 0.5s ease;
}

.todolist-modal-content button:hover {
    cursor: pointer;
    transform: scale(1.02);
}


/* Filter Section */
.cfd-container {
    position: relative;
    display: inline-block;
}
  
.cfd-icon {
    color: #3498db;
    cursor: pointer;
    transition: color 0.2s ease;
}

.cfd-icon:hover {
    color: #2980b9;
}

.cfd-dropdown {
    position: absolute;
    margin-top: 20rem;
    margin-left: 5rem;
    width: 240px;
    max-height: 17rem;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: auto;
    animation: cfd-fadeIn 0.2s ease;
}

@keyframes cfd-fadeIn {
from {
    opacity: 0;
    transform: translateY(-10px);
}
to {
    opacity: 1;
    transform: translateY(0);
}
}

.cfd-dropdown-menu {
    display: flex;
    flex-direction: column;
}

.cfd-dropdown-header {
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    border-bottom: 1px solid #eaeaea;
}

.cfd-dropdown-item {
    display: block;
    width: 100%;
    text-align: left;
    padding: 10px 16px;
    font-size: 14px;
    color: #505050;
    background: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.cfd-dropdown-item:hover {
    background-color: #f5f7fa;
}

.cfd-dropdown-item-highlight {
    color: #3498db;
    font-weight: 500;
}

.cfd-dropdown-divider {
    height: 1px;
    background-color: #eaeaea;
    margin: 4px 0;
}



@media (max-width:1276px) {
    .chat-box textarea {
        padding: 8px 0 0 10px;
        margin-left: 10px;
        margin-right:10px;
    }
}


@media (max-width: 867px) {

    .inbox-list {
        width: 100%;
        height: calc(100% - 250px);
    }
  
    .inbox-column1 {
      width: 100%;
      display: none;
    }

    .inbox-column3 {
        width: 100%;
        display: none;
    }


    .inbox-column2 {
        display: none;
    }
  
    .inbox-column1.active {
      display: block;
      height: 100vh;
      padding-top:1rem;
    }

    .inbox-column1.active .inbox-header h1 {
        padding-left: 2rem;
    }

    .inbox-column2.active {
        display: flex;
    }

    .inbox-column2.active .sidenav {
        display: none;
    }

    .inbox-column3.active {
        display: block;
        height: 100vh;
        max-width: 100dvw;
        position: fixed;
        top: 0;
        left: 0;
        background: white;
        z-index: 1000;
    }
  
    .inbox-header2 {
      margin-left: 0rem;
      display: flex;
      width: 100%;
      align-items: center;
      top: 0;
      z-index: 40;
      height:2rem;
    }

    .inbox-header3 {
        margin-left: 0rem;
        display: flex;
        width: 100%;
        align-items: center;
        top: 0;
        z-index: 40;
        height:2rem;
      }

    .inbox-header3 .back-button {
        margin-top: 10px;
    }

    .inbox-column3.active b {
        display: flex;
        text-align: center;
        justify-content: center;
    }
    
    .inbox-header2 h1 {
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size:1.5rem;
    }
    
    .back-button {
      background: none;
      border: none;
      font-size: 24px;
      margin-right: 10px;
      margin-left: 0px;
      margin-top:15px;
      cursor: pointer;
    }

    .chat-box {
        margin-left: 4rem;
        position: sticky;
        bottom: 0;
        z-index: 1000; 
    }
  }

@media (max-width: 768px) {
    .media-preview {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: rgba(0, 0, 0, 0.3); 
        backdrop-filter: blur(10px);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000000;
        border-radius: 0; 
        padding: 0;
    }

    .media-preview div {
        width:100%;
    }

    .inbox-column1 {
        padding-left: 1rem;
    }

    .inbox-column1.active .inbox-header {
        padding: 0.25rem 0 1rem 0;
        margin: 0;
    }

    .main-inbox-header {
        width: 95%;
    }

    .main-inbox-header h3{
        margin-top: 0.5rem;
        font-size: 1rem;
        white-space: nowrap; /* Prevents the text from wrapping to the next line */
        overflow: hidden; /* Hides the overflow text */
        text-overflow: ellipsis; /* Adds the ellipsis (...) */
        max-width: calc(100% - 100px);
    }
}

.audio-preview {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.audio-preview audio {
    border-radius: 30px;
    width: 300px;
    height: 40px;
    padding: 5px 15px;
}

/* Remove default styling */
.audio-preview audio::-webkit-media-controls-panel {
    background-color: #ffffff;
}

/* Style the play button */
.audio-preview audio::-webkit-media-controls-play-button {
    border-radius: 50%;
    margin-right: 10px;
}

/* Style the time slider (progress bar) */
.audio-preview audio::-webkit-media-controls-timeline {
    background-color: #ffffff;
    border-radius: 25px;
    margin: 0 10px;
}

/* Style the mute button */
.audio-preview audio::-webkit-media-controls-mute-button {
    border-radius: 50%;
}

.voice-recording-controls {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.voice-record-btn,
.voice-stop-btn,
.voice-send-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    margin-right: 10px;
}

.voice-record-btn {
    color: #002244;
}

.voice-stop-btn {
    color: #dc3545;
}

.voice-send-btn {
    vertical-align: middle;
    background-color: #002244;
    color: white;
    padding: 7px 10px;
    border: none;
    border-radius: 15px;
    font-size: 0.9rem;
}

.voice-discard-btn {
    background-color: #c92132;
    color: white; 
    padding: 8px 10px;
    border: none;
    border-radius: 15px;
}

.voice-discard-btn {
    cursor: pointer;
}

.emoji-picker-container {
    position: relative;
    display: flex;
    align-items: center;
}

.emoji-picker-popup {
    position: absolute;
    bottom: 165px;
    right:-300%;
    z-index: 1000;
}

.emoji-picker-container .chat-box-send-icon-symbol-button {
    padding: 5px;
    display: flex;
    align-items: center;
}

.emoji-picker-container .chat-box-send-icon-symbol-button:hover {
    border-radius: 4px;
}

.ai-assist-button {
    display: flex;
    align-items: center;
    width:100%;
}

.ai-assist-button button {
    padding: 8px 15px;
    border-radius: 8px;
    background: linear-gradient(135deg, #3da6db 0%, #6c32f3 100%);
    color: white;
    border: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.ai-icon {
    margin-right: 10px;
}

.ai-assist-button button:disabled {
    cursor: not-allowed;
    opacity: 0.8;
}

.ai-assist-button button.loading::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.3),
        transparent
    );
    animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(50%);
    }
}

.reply-preview {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: #f0f0f0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 10px 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.reply-content {
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
}

.reply-icon {
    font-size: 1.2em;
    color: #555;
    margin-right: 8px;
    flex-shrink: 0;
}

.reply-text {
    font-size: 0.9em;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.close-reply {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    color: #888;
    padding: 0;
    margin-left: 8px;
}

.filespan-column {
    margin-left: 10px;
}

.private-note-tab {
    height: 7rem;
}

.contact-label-container {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.label-container {
    max-width: 500px;
    margin: auto;
    padding: 1rem;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.label-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
}

.contact-label-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.label-tag-ibx {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #f0f0f0;
    color: #333;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    cursor: pointer;
}

.label-tag:hover {
    background-color: #e0e0e0;
}

.add-label {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #002244;
    color: white;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    cursor: pointer;
}

.add-label:hover {
    background-color: #003366;
}

.add-label-input {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 0.5rem;
    padding: 1.5rem 1rem;
    border: 1.5px solid black;
    border-radius: 8px;
    width: 100%;
}

.add-label-input input {
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    font-size: 1rem;
}

.color-picker {
    display: flex;
    overflow-x: auto;
    gap: 8px;
    margin-top: 0.5rem;
}

.color-option {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s;
    flex-shrink: 0; /* Prevents shrinking on mobile */
}

.color-option:hover {
    transform: scale(1.1);
}

.label-button-container {
    display: flex;
    justify-content: end;
    gap: 1rem;
}

.label-add-btn {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #002244;
    color: white;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
}

.label-cancel-btn {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #dc3545;
    color: white;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    border: none;
    transition: all 0.3s ease;
    cursor: pointer;
}

textarea {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

@media (max-width:630px) {


    .template-modal-content {
        max-width: 100%;
        padding:0;
    }

    .template-modal-container {
        width: 80%;
        position: absolute;
        background-color: white;
        min-height: 30dvh;
        border-radius: 25px;
        padding: 2rem 2rem;
        box-shadow: 0px 10px 20px -4px rgba(0, 0, 0, 0.2);
    }
}

@media (max-width:513px) {
    .media-preview button {
        padding: 8px 15px;
    }

    .filespan-column {
        display:flex;
        flex-direction: column;
    }
}

@media (max-width:370px) {
    .chat-box-send-icon-symbol-button {
        margin-right:0px;
    }

    .ai-icon {
        margin-right: 3px;
    }

    .ai-assist-button button {
        padding: 8px 10px;
    }

    .emoji-picker-container .chat-box-send-icon-symbol-button {
        padding-left: 0px;
    }
}