.plans-and-billing-main {
    padding: 5rem 10rem 0 27rem;
}

.plans-and-billing-nav {
    font-size: 1.15rem;
    margin-top: 2rem;
    display: flex;
    max-width: 40%;
}

.plans-and-billing-navitem {
    flex-grow: 1;
    cursor: pointer;
}


/* Price Section */
.plan-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    text-align: left;
}

.plan-section h1 {
    font-size: 1.5rem;
    color: rgba(58, 58, 58);
    letter-spacing: 0.05rem;
}

.header-text {
    text-align: left;
}


.plan-section p {
    text-align: left;
    margin-top: 30px;
    font-size: 0.9rem;
    color: rgba(58, 58, 58);
    font-weight: 300;
}

.plan-section i {
    padding-right: 0.4rem;
    font-size: 1.25rem;
}

.plan-price {
    color: rgba(58, 58, 58);
    font-weight: bold;
    font-size: 1.5rem;
}

.plan-month-text {
    font-size: 1rem;
    color: rgba(58, 58, 58);
    letter-spacing: 0.05rem;
    font-weight: lighter;
    margin-top: 30px;
}

.plan-limit-text {
    margin: 0 0 0 3rem;
    text-align: start;
}


.buy-button {
    padding: 10px 30px;
    width: 100%;
    border: none;
    border-radius: 10px;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-size: large;
    margin-top: auto;
    transition: all 0.5s ease;
}

.buy-button:hover {
    cursor: pointer;
    transform: translateY(-5px);
}

#plan-standard-card {
    background: #003870;
}

#plan-standard-card h1 {
    color: white;
}

#plan-standard-card p {
    color: white;
}

#plan-standard-card .plan-price {
    color: white;
}

#plan-standard-card .plan-month-text {
    color: white;
}

.buy-button1 {
    padding: 10px 30px;
    width: 100%;
    border: none;
    border-radius: 10px;
    background-color: #000000;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: large;
    margin-top: auto;
    transition: all 0.5s ease;
}

#plan-standard-card {
    padding: 0;
}

#plan-standard-card .tag {
    margin-left: 2rem;
    margin-bottom: 20px;
    width: 8rem;
    padding: 20px 0 20px 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: gold;
    font-weight: bold;
    color: white;
    font-size: 0.75rem;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.most-popular {
    margin-top:-12px;
    padding: 5px 10px;
    background: #FFE5B4;
    color: #FFA500;
    border-radius: 10px;
    font-size: clamp(0.3rem, 0.55rem, 0.7rem);
    text-transform: uppercase;
}

.price-card1 {
    width: 100%;
    border-radius: 10px;
    background-color: white;
    margin: 10px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.card-body1 {
    background-color: white;
    padding: 20px;
    margin-top:-30px;
}

.card-body2 {
    background-color: white;
    padding: 20px;
}


/* Add on content */
.add-on-plan-section {
    margin: 2rem 0 3rem 0;
}

.add-on-content {
    margin-top: 2rem;
    padding: 1.5rem 2.5rem;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border: 1px solid black;
}

.add-on-content p{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.add-on-content button {
    padding: 10px 30px;
    border: none;
    border-radius: 10px;
    background-color: #001223;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 1rem;
    margin-top: auto;
    transition: all 0.5s ease;
}

.add-on-card {
    display:flex;
    justify-content: space-between;
}

.add-on-button {
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.add-on-div {
    display: block;
    flex-wrap: wrap;
    gap: 2rem;
}

.add-on-unit {
    display: flex;
    align-items: center;
    gap: 2rem;
}


.add-on-button input {
    height: 2rem;
    width: 3rem;
    padding: 0.25rem;
    border-radius: 10px;
    margin-right: 10px;
}

@media (max-width: 1366px) {

    .most-popular {
        margin-bottom: 5px;
    }


    .plans-and-billing-navitem {
        flex-grow: 1;
    }

    .plans-and-billing-nav {
        max-width: 100%;
        justify-content: center;
    }

    .plan-section {
        flex-direction: column;
    }

    .price-card1 {
        width: 100%;
        margin: 20px 0;
        display: flex;
        flex-direction: row;
    }

    .card-header {
        width: 40%;
        border-radius: 10px 0 0 10px;
    }

    .card-body1, .card-body2 {
        width: 60%;
        border-radius: 0 10px 10px 0;
        margin-top: 0;
        padding: 20px;
    }

    .header-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .header-text h1 {
        font-size: 1.3rem;
        margin-bottom: 10px;
    }

    .plan-price {
        font-size: 1.3rem;
    }

    .plan-month-text {
        font-size: 0.9rem;
        margin-top: 15px;
    }

    .header-text p {
        font-size: 0.8rem;
        margin-top: 15px;
    }

    .buy-button, .buy-button1 {
        width: 100%;
        font-size: 1rem;
    }

    #plan-standard-card .tag {
        margin-left: 0;
        width: auto;
        padding: 5px 10px;
        border-radius: 5px;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .most-popular {
        font-size:0.5rem;
    }

    .plan-limit-text {
        margin: 0;
    }

    .card-body1 p, .card-body2 p {
        font-size: 0.8rem;
        margin-bottom: 10px;
    }
}

@media (max-width:867px) {
    .plans-and-billing-main{
        padding:2rem 2rem 0 6rem;
    }
}

@media screen and (max-width: 768px) {
    .card-header {
        width: 50%;
    }

    .card-body1, .card-body2 {
        width: 50%;
    }

    .plans-and-billing-main {
        padding-left:2rem;
    }

    .add-on-content button {
        font-size:0.8rem;
    }

    .add-on-content h2 {
        font-size:1rem;
    }

    .add-on-content p {
        font-size:0.7rem;
    }

    .add-on-content {
        padding: 1rem 2rem;
    }
}

@media (max-width:520px) {
    .add-on-card {
        display:block;
    }

    .add-on-button {
        display:flex-end;
    }
}