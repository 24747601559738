.slp-container {
    min-height: 100vh;
    padding: 2rem;
    background-color: #f8fafc;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.slp-header {
    margin-bottom: 2rem;
    text-align: center;
}

.slp-logo {
    width: 13rem;
    margin-bottom: 1rem;
}

.slp-title {
    color: #0f172a;
    font-size: 1.875rem;
    font-weight: 600;
}

.slp-card {
    width: 100%;
    max-width: 28rem;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 2rem;
}

.slp-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.slp-form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.slp-label {
    color: #475569;
    font-size: 0.875rem;
    font-weight: 500;
}

.slp-input,
.slp-select {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    font-size: 1rem;
    color: #0f172a;
    background-color: white;
    transition: all 0.2s ease;
}

.slp-input:focus,
.slp-select:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.slp-input::placeholder {
    color: #94a3b8;
}

.slp-select {
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%2394a3b8'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1.25rem;
    padding-right: 2.5rem;
}

.slp-button {
    margin-top: 1rem;
    padding: 0.875rem 1.5rem;
    background-color: #002244;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.slp-button:hover {
    background-color: #1e40af;
}

.slp-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(30, 64, 175, 0.4);
}

.slp-error-message {
    color: #dc2626;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

.slp-input.error {
    border-color: #dc2626;
}

@media (max-width: 640px) {
    .slp-container {
        padding: 1rem;
    }

    .slp-card {
        padding: 1.5rem;
    }

    .slp-title {
        font-size: 1.5rem;
    }
}
