.posts_container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
    margin-top: 8rem;
}

.featured-post {
    margin-bottom: 3rem;
}

.featured-post .post {
    display: flex;
    border-radius: 10px;
    overflow: hidden;
}

.featured-post .post_thumbnail {
    flex: 1;
    height: 400px;
}

.featured-post .post_content {
    flex: 1;
    padding: 2rem;
}

.featured-post .post_content h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.posts_container h2 {
    margin-bottom: 2rem;
    font-size: 1.8rem;
}

.recent-posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.post {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.post:hover {
    transform: translateY(-5px);
}

.post_thumbnail {
    height: 250px;
    overflow: hidden;
    background-color: #1E1C35;
}

.post_thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.post_author-avatar {
    width:2.5rem;
    aspect-ratio: 1/1;
    border-radius: 2px;
    overflow: hidden;
}

.post_content {
    padding: 1.5rem;
}

.post_content h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.post_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.category_item {
    background-color: #f0f0f0;
    padding: 0.3rem 0.8rem;
    border-radius: 20px;
    font-size: 0.9rem;
}

@media (max-width: 1024px) {
    .featured-post .post {
        flex-direction: column;
    }
    
    .featured-post .post_thumbnail {
        height: 300px;
    }
    
    .recent-posts {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .recent-posts {
        grid-template-columns: 1fr;
    }
}