.reset-password-main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
}

.reset-password-logo {
    width: 15rem;
}

.reset-password-content {
    margin-top: 2rem;
    box-shadow: 5px 5px 20px #6565652e;
    border-radius: 15px;
    padding: 2rem 4rem;
    background-color: white;
    text-align: center;
}

.reset-password-content p {
    color: #656565;
    margin-top: 1rem;
}

.reset-password-input {
    margin-top: 2rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.reset-password-input input{
    margin-bottom: 1rem;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding-left: 20px;
    width: 40vw;
    height: 40px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.reset-password-input button {
    font-size: 1rem;
    width: 40vw;
    height: 40px;
    border: none;
    background: #003870;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.reset-password-input button:hover {
    transform: scale(1.01);
    box-shadow: 5px 5px 20px #6565652e;
}

.reset-password-content span {
    text-decoration: underline;
    color:rgba(58,58,58, 0.6);
    transition: color 0.5s ease;
}

.reset-password-content span:hover {
    cursor: pointer;
    text-decoration: underline;
    color:rgba(0,56,112);
}