

.contacts-container {
    padding: 5rem 10rem;
}

.contacts-header {
    display: flex;
    justify-content:space-between;
    width:25rem;
}

.contacts-header p {
    font-size: 2rem;
}

.contacts-header:hover {
    cursor: pointer;
}

.contacts {
    margin-top: 3rem;
    height: 29rem;
    width: 75rem;
}

.flowBtn {
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #003870;
    transition: all 0.5s ease;
}

.flowBtn:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
}

.create-contact-btn {
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #003870;
    color: white;
    border: none;
    transition: all 0.5s ease;
}
.create-contact-btn:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
}

.phoneInput {
    width: 80%;
    height: 55%;
    padding: 0 0.5rem;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1); 
}

.contact-btn-section {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.contact-btn-section button {
    padding: 1rem 5rem;
    border-radius: 15px;
    background-color: #003870;
    color: white;
    border: none;
    transition: all 0.5s ease;
    width:100%;
}

.contact-btn-section button:not(:disabled):hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
}
.contactDeleteBtn {
    background-color: #EE4B2B;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 0.5rem 0.75rem;
    transition: all 0.5s ease;
}

.contactDeleteBtn:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.contactEditBtn {
    background-color: #003870;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 0.5rem 0.75rem;
    transition: all 0.5s ease;
}

.contactEditBtn:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.context-menu {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 8px;
}

.rename-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.rename-button:hover {
    background-color: #45a049;
}

.rename-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.rename-modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.rename-modal-content h2 {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 18px;
    color: #333;
}

.rename-modal-content input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.rename-modal-buttons {
    display: flex;
    justify-content: flex-end;
}

.rename-modal-buttons button {
    margin-left: 8px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.rename-modal-buttons button:first-child {
    background-color: #4CAF50;
    color: white;
}

.rename-modal-buttons button:last-child {
    background-color: #f44336;
    color: white;
}

.rename-modal-buttons button:hover {
    opacity: 0.9;
}

.chatflow-phone-input {
    width:100%;
}

.ag-row {
    transition: height 0.3s ease;
  }
 
.expanded-cell {
    height: auto; /* Adjust the height as needed */
    white-space: normal; /* Allow text to wrap */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

.quick-filter-input {
    margin-bottom: 15px;
    padding: 10px 15px;
    width: 100%;
    border: 1px solid #929292;
    border-radius: 10px;
    font-size: 16px;
    transition: all 0.3s ease;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
}

.quick-filter-input:focus {
    outline: none;
    border-color: #003870;
}

.ag-theme-quartz {
    --ag-background-color: #f8f8f8;
    --ag-header-background-color: #002244;
    --ag-header-foreground-color: #ffffff;
    --ag-odd-row-background-color: #f3f3f3;
    --ag-row-hover-color: #e6f3ff;
    --ag-selected-row-background-color: #d9e8f6;
    --ag-font-size: 14px;
    --ag-font-family: 'Arial', sans-serif;
}

.ag-theme-quartz .ag-header {
    border-bottom: 2px solid #002850;
}

.ag-theme-quartz .ag-header-cell {
    padding: 12px; /* Increase padding for header cells */
}

.ag-theme-quartz .ag-cell {
    padding: 5px 12px; /* Increase padding inside cells */
    border-right: 1px solid #e0e0e0;
}

.ag-theme-quartz .ag-row {
    border-bottom: 1px solid #e0e0e0;
    height: 50px; /* Set a consistent height for rows */
}

.contacts {
    margin-top: 3rem;
    height: calc(100vh - 250px); /* Adjust based on your layout */
    width: 100%;
    max-width: 1200px; /* or whatever maximum width you prefer */

}

.create-contact-btn {
    margin-bottom: 1rem;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #002244;
    color: white;
    border: none;
    transition: all 0.3s ease;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.create-contact-btn:hover {
    background-color: #002850;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
}

.contacts-container {
    padding: 2rem 13%;
    max-width: 90%;
    margin: 0 auto;
}

.contacts-header {
    margin-bottom: 2rem;
}

.contacts-header h1 {
    font-size: 2.5rem;
    color: #002244;
}

/*--------------------*/

.contacts-page-wrapper {
    min-height: 100vh;
    background-color: #f9fafb;
    padding: 4rem 4rem 4rem 8rem 
}

.contacts-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center; 
}

.contacts-header-buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.new-contact-button {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.5rem;
    padding-right: 1rem;
    background-color: #1e1c35;
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s;
    gap: 5px;
}

.settings-button {
    padding: 0.5rem;
    background: none;
    border: none;
    color: #6b7280;
    cursor: pointer;
    border-radius: 0.375rem;
}

.settings-button:hover {
    background-color: #f3f4f6;
}

/* Search and Filter Styles */
.search-filter-container {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.search-wrapper {
    position: relative;
    flex-grow: 1;
}

.search-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
}

.search-input {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 2.5rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    font-size: 0.875rem;
}

.search-input:focus {
    outline: none;
    border-color: #7c3aed;
    box-shadow: 0 0 0 2px rgba(124, 58, 237, 0.2);
}

.filter-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    color: #4b5563;
    cursor: pointer;
}

.filter-button:hover {
    background-color: #f9fafb;
}

/* Table Styles */
.contacts-table-container {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: auto;
}

.contacts-table {
    width: 100%;
    border-collapse: collapse;
}

.contact-tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 4px;
}

.contact-tag {
    background-color: #e8edff;
    color: #3a46ed;
    padding: 2px 8px;
    border-radius: 4px;
}

.status-tag-contact {
    background-color: #f7e8ff;
    color: #de3aed;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 15px;
}

.table-header {
    padding: 0.75rem 1rem;
    white-space: nowrap;
    text-align: left;
    font-size: 0.75rem;

    text-transform: uppercase;
    color: #6b7280;
    background-color: #f9fafb;
    border-bottom: 1px solid #e5e7eb;
}


.table-row {
    transition: background-color 0.2s;
}

.table-row:hover {
    background-color: #f9fafb;
}

.table-cell {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
}

.table-cell input {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.2)
}
.contact-name-cell {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.contact-avatar {
    width: 50px;
    height: 40px;
    background-color: #e8edff;
    color: #3a46ed;
    margin-right: 7px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-name {
    font-weight: 500;
    color: #111827;
}

.contact-action-buttons {
    display: flex;
    gap: 0.5rem;
    border-bottom: 0;
}

.contact-edit-button {
    padding: 0.5rem 1rem;
    background-color: #1e1c35;
    color: white;
    border: none;
    border-radius: 0.75rem;
    cursor: pointer;
}

.contact-delete-button {
    padding: 0.25rem 0.75rem;
    background-color: #ef4444;
    color: white;
    border: none;
    border-radius: 0.75rem;
    cursor: pointer;
}

.delete-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.2);
    z-index: 10000;
}

.delete-yes {
    background-color: #ef4444;
}

.delete-no {
    background-color: #002244;
}

.delete-modal-buttons button {
    padding: 0.5rem 1.25rem;
    border: none;
    border-radius: 10px;
    color: white;
    transition: all 0.5s ease;
}

.delete-modal-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: flex-end;
}

/* Loading Spinner */
.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16rem;
}

.loading-spinner {
    width: 2rem;
    height: 2rem;
    border: 2px solid #e5e7eb;
    border-top-color: #7c3aed;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@media (max-width:768px) {
    .contacts-container{
        padding-left: 2rem;
    }
}
