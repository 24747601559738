.settings-main {
    padding: 0rem 0rem 0 0rem;
}
 
.main-content-settings h2 {
    color: black;
    font-size: 1.25rem;
    font-weight: 600;
}
.settings-profile-pic {
    margin-top: 2rem;
}

.settings-profile-img{
    display: flex;
    align-items: center;
}

.settings-profile-img img {
    border: 1px solid black;
    border-radius: 50%;
}

.upload-sec {
    display: flex;
    flex-direction: column;
    margin-left: 4rem;
}

.custom-file-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 0;
    cursor: pointer;
    color: white;
    background: #002244;
    border: 1px solid #ccc;
    border-radius: 15px;
    transition: all 0.5s ease;
}

.custom-file-upload:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.upload-sec input[type='file'] {
    display: none;
}


.upload-text{
    margin-top: 1rem;
    font-size: 0.9rem;
    color:rgba(58, 58, 58, 0.5)
}

.input-box-settings {
    margin-top: 2.5rem;
}

.settings-address {
    min-height: 2rem !important;
}

.input-box-settings .input-container textarea {
    font-size: 1rem;
    padding: 1rem 1rem 1rem 1rem;
    width: 100%;
    min-height: 7rem !important;
    border-radius: 10px;
}

.input-box-settings input {
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 1.5rem;
    padding-left: 1rem;
    width: 20rem;
    height: 40px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1); 
    border-radius: 10px;
}

.settings-website {
    margin-top: 0.5rem;
}
.settings-website .input-container input {
    width: 80%;
    margin-top: 0 !important;
}

.input-box-settings select {
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 1.5rem;
    padding: 0 0 0 1rem;
    width: 20rem;
    height: 40px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1); 
    border-radius: 10px;
}

.input-box-settings input::placeholder {
    color:rgba(58,58,58, 0.35);
}

.input-box-select-flex {
    display: flex;
    width: 100%;
    gap: 4rem;
}

.input-box-select-flex div {
    width: 100%;
}

.input-box-select {
    margin-top: 2rem;
    width: 45%;
}

.input-box-select h2 {
    margin-bottom: 1rem;
}


.settings-button button {
    margin-top: 1rem;
    padding: 1rem 5rem;
    border: none;
    background: #002244;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: white;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.5s ease;
}

.settings-button button:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.account-data {
    background-color: white;
    padding: 20px;
    margin-top: 20px;
}

.account-data h2 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
}

.account-data p {
    margin-bottom: 20px;
    color: #666;
}

.plan-data {
    background-color: #f8f8f8;
    border-radius: 10px;
    padding: 20px;
}

.plan-data h3 {
    margin-bottom: 20px;
    font-size: 1.2em;
    color: #333;
}

.plan-data h3 span {
    font-size: 0.8em;
    color: #4CAF50;
    background-color: #E8F5E9;
    padding: 5px 10px;
    border-radius: 20px;
    margin-left: 10px;
}

.plan-data h3 span {
    font-size: 0.8rem;
    color: #4CAF50;
    background-color: #E8F5E9;
    padding: 5px 10px;
    border-radius: 20px;
}

.plan-data p {
    display:flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 0.9rem;
}

.plan-data i {
    margin-right: 10px;
}

.current-plan {
    border: 2px solid #1e1c35;
}

.plan-data-item {
    margin-bottom: 20px;
}

.plan-data-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.plan-data-item-title {
    font-weight: bold;
    flex: 1;
}

.plan-data-item-bar {
    display: block;
    align-items: center;
    flex: 2;
    text-align: center;
    margin-top: 10px;
}

.progress-bar {
    margin-top: 1rem;
    flex: 1;
    height: 5px;
    background-color: #c6c6c6;
    border-radius: 4px;
    overflow: hidden;
}

.progress {
    height: 100%;
    background-color: #4a90e2;
    transition: width 0.5s ease-in-out;
}

.plan-data-item-details {
    margin-top:10px;
    font-size: 0.9em;
    color: rgba(58, 58, 58, 0.7);;
}

.settings-main {
    display: flex;
    height: 100vh;
}

.settings-column1,
.settings-column2 {
    overflow-y: auto;
    background-color: white;
}

.settings-column2 {
    flex-grow: 1;
}

.settings-header {
    display: flex;
    align-items: center;
    padding: 10px;
}

.back-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    margin-right: 10px;
    cursor: pointer;
}

.main-content-settings {
    padding:5rem;
    margin-left:20rem;
}


.settings-main-active {
    display: block !important;
}


@media (max-width: 867px) {
    .sides{
        display:none;
    }

    .settings-column1,
    .settings-column2 {
        display: block;
        width: 100%;
    }

    .settings-column1.active {
        display: block;
        width: 100%;
    }
    
    .settings-column2.active {
        display:none;
    }

    .settings-column1 {
        display: block;
        position: relative;
    }
    
    .settings-column2 {
        display: block;
    }

    .settings-nav {
        width: 100%;
        padding: 4dvh 1rem 6rem 1rem;
    }

    .settings-content-area {
        padding: 1rem;
    }

    .settings-header {
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #e0e0e0;
    }

    .back-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        margin-right: 10px;
        cursor: pointer;
    }

    .sett-link1 {
        background: none;
        border: none;
        color:black;
        font-size: 1.5rem;
        margin-left: 100px;
        margin-right:10px;
        cursor: pointer;
    }

    .main-content-settings {
        margin-left: 0;
        padding: 0 0 0 6rem;
    }

    .settings-main {
        padding:0 0rem 0 0;
    }

    .password-and-security-header {
        display:flex;
    }

    .sett-link {
        font-size:2rem;
        margin-right:20px;
        color: black;
    }
    
    .settings-account-main hr {
        display:none;
    }

}

@media (max-width: 768px) {
    .plan-data {
        width: 100%;
    }

    .sett-link1{
        margin-left: 20px;
    }

    .main-content-settings {
        padding-left:1rem;
    }
}

/* Format Selection Styles */
.format-selector {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
}

.format-btn {
    padding: 0.5rem 1rem;
    border: 1px solid #002244;
    background: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.format-btn.active {
    background: #002244;
    color: white;
}

/* Format 2 Specific Styles */
.chatbot-settings-format2 {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.chatbot-input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.chatbot-input {
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.chatbot-textarea {
    width: 100%;
    min-height: 100px;
    resize: vertical; /* Allow vertical resizing */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 1rem;
    line-height: 1.5;
}

.chatbot-qa-section {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.chatbot-qa-section input:last-child {
    width: 50px;
}

/* Q&A Section Styles */
.qa-section {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.qa-section .input-container {
    margin: 10px 0;
}

.qa-section input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.qa-section label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #999;
    pointer-events: none;
    transition: 0.3s ease all;
}

.qa-section input:focus ~ label,
.qa-section input:not(:placeholder-shown) ~ label {
    top: 0;
    font-size: 12px;
    color: #333;
    background-color: white;
    padding: 0 5px;
}

.qa-section input::placeholder {
    color: transparent;
}

/* Q&A Section Header Styles */
.qa-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.qa-header h3 {
    margin: 0;
    color: #333;
    font-size: 1.1rem;
}

.delete-icon {
    color: #dc3545;
    cursor: pointer;
    transition: all 0.3s ease;
}

.delete-icon:hover {
    color: #c82333;
    transform: scale(1.1);
}

.qna-pair {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
}

.qna-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.qna-input-group {
    margin-bottom: 10px;
}

.qna-label {
    font-weight: 500;
    color: #333;
    margin-bottom: 5px;
    display: block;
}

.qna-textarea {
    width: 100%;
    min-height: 80px !important; /* Smaller height */
    max-height: 150px;
    resize: vertical;
    padding: 8px;
    margin-top: 4px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
    white-space: pre-wrap;
}

.delete-qa-btn {
    background: none;
    border: none;
    color: #ff4444;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
}

.delete-qa-btn:hover {
    color: #cc0000;
}