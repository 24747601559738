.add-whatsapp-template-btn {
    padding: 0.5rem 1.5rem;
    border-radius: 10px;
    border: none;
    color: white;
    margin-left: auto;
    background-color: #002244;
    transition: all 0.5s ease;
}

.add-whatsapp-template-btn:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.template-tag-list {
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
    max-width: 90dvw;
    overflow-y:hidden;
    overflow-x: scroll;
}
.template-tag-list::-webkit-scrollbar {
    height: 6px;
}

.template-tag-list::-webkit-scrollbar-track {
    background: transparent;
}

.template-tag-list::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
}

.whatsapp-template-list {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 10rem;
}

.template-container {
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 1.5rem;
    transition: transform 0.5s ease;
}

.template-container:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.template-content {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: flex-start;
}
.template-container-header {
    display: flex;
    gap: 1.75rem;
    justify-content: space-between;
    align-items: center;
}

.settings-template-overview {
    display: flex;
    gap: 2rem;
}

.add-template-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.template-container h3 {
    font-size: 1rem;
    font-weight: 600;
}
.template-container p {
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.template-status {
    border-radius: 50%;
    height: 0.75rem;
    width: 0.75rem;
}
.template-tag {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.35rem 1rem;
    border-radius: 20px;
    background-color: #0022442d;
    transition: all 0.5s ease;
}
.template-tag:hover {
    cursor: pointer;
    transform: scale(1.05)
}
.delete-template-btn {
    padding: 0.2rem;
    width: 2rem;
    height: 2rem;
    border-radius: 5px;
    border: 1px solid #00000000;
    font-size: 1rem;
    color:#e025008b;
    box-shadow: 0 0 2px #0000001b;
    transition:all 0.5s ease;
}
.delete-template-btn:hover {
    cursor: pointer;
    border: 1px solid #0000001b;
    background-color: #0000001b;
}



/* Preview template modal */
.template-preview-main {
    background-color: white;
    border-radius: 15px;
    padding: 3rem;
    max-width: 60dvw;
    min-width: 60dvw;
}

.template-preview-header {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.template-back-btn:hover {
    cursor: pointer;
}

.template-preview-content {
    border: 1px solid black;
    margin-top: 2rem;
    border-radius: 20px;
    background-color: rgba(170, 255, 43, 0.601);
    overflow-y: scroll;
    max-height: 80dvh;
    padding: 2rem;
    overflow-wrap: break-word;
}

.text-tag {
    background-color: rgba(188, 188, 188, 0.5);
    border-radius: 5px;
    margin: 0 0.5rem;
    padding: 0.1rem 1rem;
}
.template-header {
    margin-bottom: 2rem;
}

.template-preview-content pre {
    white-space: pre-wrap;
    word-break: break-word;
    overflow-wrap: break-word;
}

.template-preview-content::-webkit-scrollbar {
    width: 6px;
}

.template-preview-content::-webkit-scrollbar-track {
    background: transparent;
}

.template-preview-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
}




/* template */
.whatsapp-template-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.settings-template-modal-container {
    padding: 2rem;
    border-radius: 15px;
    background-color: white;
    width: 90dvw;
    overflow-y: scroll;
}

.whatsapp-template-modal-content {
    border-radius: 15px;
}

.add-template-container {
    border-radius: 15px;
    max-height: 85dvh;
    margin-top: 2rem;
    padding: 1rem 0 1rem 2rem;
    border: 1px solid black;
}

.add-template-body {
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
    max-height: 60dvh;
}

.add-template-header {
    display: flex;
    gap: 2rem;
}

.add-template-text-btn {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.add-template-text-btn button {
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    border: 1px solid black;
    transition: all 0.5s ease;
}

.add-template-text-btn button:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.add-template-file {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .add-template-file input {
    margin-bottom: 2rem;
} */

.template-file-preview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.template-file-preview > * {
    max-width: 50%;
    border-radius: 10px;
}

.file-input-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-top: 0.5rem;
}

.file-size-limit {
    font-size: 0.75rem;
    color: #666;
}

.remove-file-button {
    position: absolute;
    border: none;
    top: 0.5rem;  
    right: 0.5rem; 
    width: 1.5rem;
    height: 1.5rem;
    background-color: #ef4444; 
    color: #ffffff;
    border-radius: 50%; 
    transition: background-color 0.2s;
}

.remove-file-button:hover {
    background-color: #dc2626;  /* red-600 */
}

.remove-file-button:focus {
    outline: none;
}

.remove-file-button:focus-visible {
    outline: 2px solid #ef4444;  /* red-500 */
    outline-offset: 2px;
}
.modal-header {
    display: flex;
    align-items: center;
    gap: 2rem;
    transition: all 1s ease;
}

.whatsapp-template-file {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.modal-param {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.modal-param input {
    padding: 0.5rem;
    border-radius: 10px;
    border: 1px solid #003870;
}


.whatsapp-template-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.whatsapp-template-btn button {
    width: 100%;
    padding: 1rem 4.5rem;
    border-radius: 10px;
    border: none;
    background-color: #003870;
    color: #f2f2f2;
    transition: transform 0.5s ease;
}


.whatsapp-template-btn button:hover {
    cursor: pointer;
    transform: translateY(-5px);
}

.template-delete-btn {
    background-color: #cc2706 !important;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 0.5rem 0.75rem;
    transition: all 0.5s ease;
}

.template-delete-btn:hover {
    cursor: pointer;
    transform: scale(1.01) !important;
}



.add-template-preview {
    margin: 2rem 0 0 0;
    padding: 2rem;
    background-color: rgba(170, 255, 43, 0.601);
    width: 40%;
    max-width: 40%;    
    border-radius: 15px;
}

.add-template-preview div {
    overflow-y: scroll;
    max-height: 55dvh;
}

.add-template-preview pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    border-radius: 0.25rem;
    margin: 2rem 0 0 0;
    font-size: 0.875rem;
}

.add-template-preview div::-webkit-scrollbar {
    width: 6px;
}

.add-template-preview div::-webkit-scrollbar-track {
    background: transparent;
}

.add-template-preview div::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
}

.settings-add-template-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.settings-add-template-btn  button{
    font-size: 1rem;
    background-color: #002244;
    color: white;
    border-radius: 10px;
    border: none;
    padding: 0.5rem 1rem;
}

@media (max-width:867px) {
    .settings-account-main{
        padding-left:6rem;
    }

    .add-whatsapp-template-btn {
        margin-top: 2rem;
    }
}

@media (max-width:768px) {
   .whatsapp-template-modal-content {
    overflow-y: auto;
    max-height: 70vh;
    padding :20px;
    width:70%;
   }

   .settings-account-main {
    padding-top:1rem;
    padding-left: 2rem;
   }
}