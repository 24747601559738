.flow-container {
    display:flex;
    width: 100dvw;
    height:92vh;
}


.flow-topBar {
    width: 100%;
    height: 8dvh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 4rem;
    box-shadow: 0 0px 15px 0 rgba(0,0,0,0.2);
}

.flow-head {
    display: flex;
    align-items: center;
    gap: 3rem;
}

.flow-head input {
    border: none;
    border-bottom: 1.5px solid rgba(0,0,0,0.2);
    padding-bottom: 0.25rem;
    font-size: 1.25rem;
    font-weight: bold;
}

.flow-head input:focus {
    outline: none;
    border-bottom: 2px solid rgba(8, 28, 106, 0.424);
}

.flow-back {
    font-size: 1.2rem;
    background-color: white;
    border: none;
    transition: all 0.4s ease;
}
.flow-back:hover {
    cursor: pointer;
    transform: scale(1.15)
}
.flow-save {
    width: 13rem;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
}
.flow-save button {
    color: white;
    background-color: #002244;
    padding: 0.7rem 1.5rem;
    border: none;
    border-radius: 10px;
    transition: all 0.4s ease;
}
.flow-save button:hover {
    cursor: pointer;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
    transform: scale(1.05);
}



.flow-sidebar {
    padding: 3rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    width: 20%;
    height: auto;
    background-color: white;
    border-left: 1px solid rgba(173, 173, 173, 0.619);
    overflow-y:scroll;
    /* background-color: rgba(214, 214, 214, 0.5); */
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.1);
}


.sidebar-components {
    display: flex;
    flex-direction: column;
 }
.sidebar-components button {
    background-color: white;
    margin-top: 2rem;
    border: 1px solid rgba(171, 171, 171, 0.5);
    border-radius: 20px;
    height: 5rem; 
    box-shadow: 0 5px 5px rgba(85, 85, 85, 0.1);
    transition: all 0.3s ease;
}

.flow-sidebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}





/* Node Style Part (Side selection bar) ------------------------ */
#side-conditionNode {
    background-color: #ADACB5;
}

#side-msgNode {
    background-color: #2D3142;
    color: #FFFFFF;
}

#side-templateNode {
    background-color: #2D3142;
    color: #FFFFFF;
}

#side-mediaNode {
    background-color: #B0D7FF;
}

#side-todolistNode {
    background-color: #EAE8FF;
}

#side-unitNode {
    background-color: #EAE8FF;
}

.sidebar-components button:hover {
    cursor: pointer;
    transform: scale(1.05);
}
/* ------------------------------------------------------------ */