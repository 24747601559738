/* Broadcast Styles */
.broadcast-main {
    padding: 4rem 2rem 4rem 10rem; /* Default padding for larger screens */
}

.broadcast-container {
    margin-top: 3rem;
}

.broadcast-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.broadcast-section h2 {
    margin-bottom: 15px;
    color: #333;
}

.template-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.filter-section {
    margin: 20px 0;
}

.filter-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.filter-controls {
    display: flex;
    gap: 1rem;
    align-items: center;
    overflow-x: scroll;
}

.broad-filter-select {
    width: 200px;
    padding:10px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    box-shadow: none;
}

.broadcast-filter-icon {
    margin-top:8px;
    font-size:1.3rem;
}

.contacts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.contact-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.contact-item:hover {
    background-color: #f5f5f5;
}

.contact-item.selected {
    background-color: #e3f2fd;
    border-color: black;
}

.broadcast-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.broadcast-button {
    padding: 10px 20px;
    background-color: #002244;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
}

.broadcast-button:hover:not(:disabled) {
    background-color: #002244;
}

.broadcast-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.filter-button {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    border: 1px solid #e5e7eb;
}

.broadcast-section h2 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
.filter-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
    align-items: flex-end;
}

.filter-column {
    display: flex;
    flex-direction: column;
    min-width: 200px;
}

.filter-column label {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 0.5rem;
}

.filter-select, .date-input {
    height: 38px;
    border-radius: 4px;
    background-color: white;
}

.filter-button-column {
    display: flex;
    align-items: flex-end;
}

.apply-filter {
    background-color: #f0f0f0;
    color: #333;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    height: 38px;
}

.selection-buttons {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.select-button {
    background-color: #002244;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
}

.cancel-button {
    background-color: #f0f0f0;
    color: #333;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
}

.cancel-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.contacts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
}

.contact-item {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

.contact-item.selected {
    background-color: #e6f7ff;
}

.filter-button.select-all {
    background-color: #002244;
    color: white;
    border: none;
}

.filter-button:hover:not(:disabled) {
    opacity: 0.9;
}

.selection-buttons {
    display: flex;
    gap: 10px;
}

.date-filter-group {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
  }
  
  .date-filter {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .date-filter input[type="date"] {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-width: 150px;
  }




@media (max-width:768px) {
    .broadcast-main {
        padding-top: 2rem; 
        padding-right: 20px;
        padding-left: 2rem;
    }
}