.main-link {
    padding-left:5rem ;
    background: #C9D6FF;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #E2E2E2, #C9D6FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    min-height:100dvh;
}

.title h1 {
    font-size: 2rem;
}

.title {
    padding-top:4rem;
    padding-bottom:2rem;
    padding-left:2rem;
}

.connect-header {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.connect-header h1 {
    margin-top: 2rem;
    margin-left: 2rem;
}

.connect-header button {
    padding: 15px 20px;
    border-radius: 10px;
    background-color: #002244;
    color: white;
    border: none;
    transition: all 0.3s ease;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.connect-header button svg {
    margin-right: 10px;
    font-size: medium;
    font-size: 1rem;
}

.connect-header button:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0 2px 10px rgba(0,0,0,0.5);
}

.linked-phone {
    border-radius: 20px 20px 0 0 ;
    margin-top:0px;
    min-height: 100dvh;
    background-color: white;
    padding:2rem;
}

.linked-phone h2 {
    font-size: 1.7rem;
}

.phone-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.phone-list li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.connect-hint{
    margin-top: 40px;
}

.connect-hint p {
    display: flex;
    justify-content: end;
    font-style: italic;
    color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
    margin-bottom: 3px;
}

.phone-item {
    width: 100%;
    height: 17dvh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 10px 3%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease;
}

.phone-item:hover {
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0 2px 10px rgba(0,0,0,0.5);
}


.phone-item-text span{
    margin-bottom: 20px;
    display:block;
    color: black;
}

.phone-no {
    font-size: 1.3rem;
    font-weight: bold;
}

.edit-btn {
    height: 60px;
    padding: 0 3rem;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    letter-spacing: 0.0rem;
    background: #002244;
    color:white;
    transition: all 0.5s ease;
}

.edit-btn:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.phone-item.active .edit-btn {
    background: white;
    color:black;
}

.phone-item.active .phone-item-text span {
    color:white;
}

.link-no-account {
    color:rgba(0, 0, 0, 0.4);
    min-height: 60dvh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-no-account span {
    margin-left: 1rem;
}

.phone-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 0;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.5s ease;
    border-radius: 15px;
    background-color: white;
    border: 1px solid black;
    padding: 0 15px;
    opacity: 0;
}

.phone-link.expanded {
    max-height: 100px; /* Adjust based on content height */
    padding: 15px;
    opacity: 1;
}

.phone-link span {
    display: block;
    word-break: break-all;
}

.phone-link button {
    background-color: #002244;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 10px;
}


.phone-link button:hover {
    cursor: pointer;
}

/* Responsive design */
@media (max-width: 1024px) {
    .title {
        padding-top:1rem;
        padding-bottom:1rem;
    }
    .title-main{
        display:flex;
        justify-content: space-between;
        padding: 2rem 1rem;
    }
    .main-link {
        padding: 0rem;
        padding-left: 5rem;
        background: linear-gradient(to right, rgba(18, 43, 98, 0.2), rgba(20, 26, 147, 0.2), rgba(153, 169, 234, 0.2));
        height:100vh;
    }
    .connect-header button {
        padding: 1rem 2rem;
        font-size: 1rem;
    }

    .linked-phone {
        padding:2rem;
    }
    .linked-phone h2 {
        font-size: 1.5rem;
    }
    .edit-btn {
        height: 60px;
        padding: 0 2rem;
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .main-link {
        padding: 0rem;
        padding-left: 0rem;
        background: linear-gradient(to right, rgba(18, 43, 98, 0.2), rgba(20, 26, 147, 0.2), rgba(153, 169, 234, 0.2));
        height:100vh;
    }

    .title-main {
        display:flex;
        align-items: center;
        justify-content: flex-start;
        padding-left:0rem;
        padding-top:2rem;
    }
    
    .title h1 {
        font-size: 1.5rem;
    }

    .connect-header {
        margin-top: 1rem;
        align-items: flex-start;
        padding-right:30px;
    }

    .phone-list li {
        flex-direction: column; 
        align-items: flex-start;
    }

    .phone-item span {
        margin-bottom: 10px;
    }

    .connect-header img {
        width:2rem;
        margin-top: 14px;
    }

    .connect-header h1 {
        margin-top: 1rem;
        margin-left: 0;
    }
    .connect-header button {
        margin-top: 1rem;
        margin-left: 0;
        width: 100%; 
    }


    .linked-phone h2 {
        font-size: 1.3rem;
    }
    .phone-item {
        padding:1rem;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height:20vh;
    }
    .edit-btn {
        height: 50px;
        width: 100%;
        padding: 0 1.5rem;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .main-link {
        padding: 0rem;
        padding-left: 0rem;
        padding-right:0rem;
        background: linear-gradient(to right, rgba(18, 43, 98, 0.2), rgba(20, 26, 147, 0.2), rgba(153, 169, 234, 0.2));
        height:100vh;
    }

    .link-active-phone-number{
        margin-top:20px;
    }

    .linked-phone {
        border-radius: 5% ;
        margin-top:0px;
        height:100%;
        background-color: white;
        padding-left: 0rem;
        padding-right: 0rem;
    }

    .title {
        padding:0;
    }

    .title h1 {
        font-size: 1.25rem;
    }

    .title-main {
        display:flex;
        align-items: center;
        justify-content: flex-start;
        padding-left:2rem;
        padding-top:2rem;
    }

    .title-main h1 {
        margin-left:10px;
    }

    .connect-header{
        align-items: center;
        justify-content: flex-start;
        padding-left:2rem;
    }

    .connect-header button {
        padding: 0.8rem 1.5rem;
        font-size: 0.9rem;
    }

    .connected-phones {
        padding:1rem;
    }

    .phone-item {
        padding: 1.5rem;
        align-items: flex-start;
        margin-top: 10px;
        height:23vh;
    }

    .phone-item span {
        margin-bottom: 10px;
        color:black;
    }

    .edit-btn {
        height: 40px;
        padding: 0 1rem;
        font-size: 0.8rem;
        margin-top: 10px;
        margin-bottom: 10px;
    }


    .phone-item.active .edit-btn {
        background: white;
        color:black;
    }
    
    .phone-item.active .phone-item-text span {
        color:white;
    }
}