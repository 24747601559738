.test-modal-main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    max-width: 100dvw;
    min-height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100001;
}

.modal-container {
    position: absolute;
    width: 50%;
    height: 90%;
    background-color: white;
    border-radius: 25px;
    padding: 3rem 0rem 0rem 3rem;
    box-shadow: 0px 10px 20px -4px rgba(0,0,0,0.2);
    overflow: hidden; /* Hide overflow to respect border-radius */
}

.modal {
    overflow-y: scroll;
    height: 100%;
    padding-right: 3rem; 
    box-sizing: content-box; /* Ensure padding does not affect the width */
    scrollbar-color: #003870;
}

/* HEADER */
.test-modal-header {
    display: flex;
    gap: 2rem;
}

.test-modal-icon {
    transition: all 0.5s ease;
}

.test-modal-icon:hover {
    cursor: pointer;
    transform: scale(1.1);
}


/* CONTACT BODY */
.test-modal-contact {
    margin-top: 2rem;
    font-weight: medium;
    font-size: 1rem;
}

.input-container {
    position: relative;
    margin: 20px 0;
}

.input-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.input-container label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #999;
    pointer-events: none;
    transition: 0.3s ease all;
}

.input-container input:focus ~ label,
input:not(:placeholder-shown) ~ label {
    top: 0;
    font-size: 12px;
    color: #333;
    background-color: white;
    padding: 0 5px;
}

.input-container input::placeholder {
    color: transparent;
}

/* Add unit button */
.test-modal-add {
    border: #33333348 dashed 1.5px;
    border-radius: 15px;
    color: #33333348;
    padding: 3rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    transition: all 0.5s ease;
}

.test-modal-add:hover {
    font-weight: 500;
    cursor: pointer;
    border: #33333300 dashed 1.5px;
    color: #00000073;
    background-color: #9696962d;
    box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.2);
}

/* Building div */
.building-div {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1.5px solid black;
    padding: 1rem 1.5rem 2rem 1.5rem;
    border-radius: 15px;
    box-shadow: 0px 5px 10px -4px rgba(0,0,0,0.2);
    max-height: 0;
    opacity: 0;
    transition: all 0.5s ease;
}

.building-div.visible {
    max-height: 700px;
    opacity: 1;
}

.building-div:hover {
    cursor: pointer;
    box-shadow: 0px 5px 15px -4px rgba(0,0,0,0.5);
}

.building-div .delete-icon {
    color: rgb(190, 55, 55, 0);
    transition: all 0.5s ease;
}

.building-div:hover .delete-icon {
    color: rgb(220, 66, 66);
}

.building-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.building-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.test-modal-date {
    display:flex;
    gap: 0.75rem;
}

.test-modal-date label{
    font-size: 0.75rem;
    font-weight: 300;
    color:#00000056
}

.test-modal-date p {
    margin-top: 0.6rem;
    font-size: 0.7rem;
    color:#00000075;
}

.react-datepicker__tab-loop {
    width: 0;
}

/* Add contact button */
.test-modal-add-btn {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: end;
    transition: transform 0.5s ease;
}

.test-modal-add-btn.clicked {
    transform: translateY(200px)
}
.test-modal-btn {
    font-weight: 500;
    border: none;
    border-radius: 15px;
    background-color: #002244;
    color: white;
    padding: 0.75rem 2rem;
    transition: all 0.5s ease;
}

.test-modal-btn:hover {
    cursor: pointer;
    box-shadow: 0px 5px 15px -4px rgba(0,0,0,0.3);
}

.test-modal-btn:disabled {
    cursor: not-allowed;
    background-color: #3b4957 !important;
}


/* Sliding button */
.switch-div {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;
    gap: 1rem;
    align-items: center;
}

.switch-div p {
    font-size: 0.8rem;
}

.switch {
    margin-top: 5px;
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider-btn {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 1.5rem;  /* Match the height for full roundness */
}

.slider-btn:before {
    position: absolute;
    content: "";
    height: 1.3rem;  /* Slightly smaller than the switch height */
    width: 1.3rem;   /* Make it a circle by matching the height */
    left: 0.1rem;    /* Small gap from the left */
    bottom: 0.1rem;  /* Small gap from the bottom */
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider-btn {
    background-color: #002244;
}

input:checked + .slider-btn:before {
    transform: translateX(1.5rem);  /* Move by the width of the switch minus the width of the button */
}

.checkin-option{
    display:flex;
    flex-direction: column;
}

.checkin-container {
    width:100%;
}

#status {
    margin-top: 20px;
}

@media (max-width:1140px) {
    .modal-container {
        padding: 2rem 0 0 2rem;
    }

    .modal {
        padding-right: 2rem;
    }
}

@media (max-width:876px) {
    .modal-container {
        width:90%;
    }
}



@media (max-width:567px) {
    .modal-container {
        width:90%;
    }

    .test-modal-date {
        display:block;
    }
}


@media (max-width:467px) {
    .test-modal-add p {
        text-align: center;
        font-size: 0.8rem;
    }

    .test-modal-add {
        padding: 1.5rem 2.5rem;
    }
}

@media (max-width:400px) {
    .modal-container {
        width:90%;
        padding: 1.5rem 0 0 1.5rem;
    }

    .modal {
        padding-right: 1.5rem;
    }

    .building-div {
        padding: 1rem;
    }

    .test-modal-header h2 {
        font-size: 1.25rem;
    }

    .test-modal-add-btn {
        justify-content: center;
    }
}