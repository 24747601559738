.todolist-container {
    padding: 4rem 2rem 4rem 10rem; /* Default padding for larger screens */
}


/* Reservation Section */
.reservation-container {
    margin-top: 2rem;
    margin-bottom: 5rem;
    padding-left: 7rem;
    padding-right: 2rem;
}

.reservation-header {
    margin-top: 1.25rem;
    display: flex;
    gap: 1.25rem;
    padding-bottom: 2rem;
    overflow-x: scroll;
    background-color: transparent;
}

.reservation-header button {
    border-radius: 1rem;
    background-color: white;
    border: solid 1.3px rgba(0, 0, 0, 0.2);
    padding: 0.6rem 0.6rem;
    transition: all 0.5s ease;
}

.reservation-header button:hover {
    border: solid 1.3px #002244;
    cursor: pointer;
}

.reservation-body {
  margin-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  pointer-events: none;
}

/* .reservation-body::-webkit-scrollbar {
    height: 6px;
}

.reservation-body::-webkit-scrollbar-track {
    background: transparent;
}

.reservation-body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
} */


.reservation-card, .reservation-empty {
    margin: 0.5rem 0 0 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    padding: 1.5rem;
    width: 25rem;
    min-height: 11rem;
    flex: 1 1 300px;
    display: flex;
    max-width: 450px; 
    flex-direction: column;
    gap: 1rem;
    transition: all 0.5s ease;
    pointer-events: none;
}

.reservation-card p {
  color:#3a46ed;
}

.reservation-empty {
  color:black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reservation-unit-data {
  margin-top: 5px;
  width: 100%;
  border: 1px solid #e8edff;
  padding:6px;
  border-radius: 8px;
  background-color: #e8edff;
}

.reservation-date-data {
  display:flex;
  gap:0.5rem;
  margin-bottom: 0.5rem;
}

.reservation-date-data h3 {
  display: flex;
  align-items: center;
}

.reservation-date-icon {
  width: 40px;
  height: 35px;
  background-color: #e8edff;
  color:#3a46ed;
  margin-right: 7px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reservation-card:hover {
    cursor: pointer;
    transform: scale(1.02);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.reservation-card.empty {
    display: flex;
    justify-content: center;
    align-items: center;
}

.reservation-name-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.reservation-name-section p {
    color: #000000;
}


.reservation-building {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.35rem;
}


.todo-container {
  margin-left:5rem;
  padding: 24px;
}

.todo-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.search-container {
  margin-bottom: 24px;
}

.search-box {
  margin-bottom: 16px;
}

.search-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
}

.add-task-container {
  display: flex;
  gap: 12px;
}

.task-type-select {
  padding: 8px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background-color: white;
  min-width: 120px;
}

.new-task-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
}

.add-button-todo {
  background-color: #2563eb;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #1d4ed8;
}

.task-section {
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 12px;
  overflow: hidden;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  background-color: white;
}

.section-indicators {
  display: flex;
  align-items: center;
}

.notification-todo-dot {
  width: 8px;
  height: 8px;
  background-color: #ff3b30;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
}

.arrow-icon {
  font-size: 20px;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}

.task-count {
  color: #6b7280;
  font-size: 14px;
}

.arrow-icon {
  color: #6b7280;
}

.section-content {
  padding: 16px;
  border-top: 1px solid #e5e7eb;
  background-color: #fafafa;
}

.phone-numbers-section {
  margin-bottom: 16px;
}

.phone-numbers-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.phone-numbers-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.phone-number-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.remove-phone-button {
  color: #6b7280;
  border: none;
  background: none;
  cursor: pointer;
  padding: 2px 6px;
}

.remove-phone-button:hover {
  color: #ef4444;
}

.add-phone-container {
  display: flex;
  gap: 8px;
}

.phone-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
}

.add-phone-button {
  background-color: #2563eb;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.add-phone-button:hover {
  background-color: #1d4ed8;
}

.tasks-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.task-item {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
}

.task-tick {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  color: #666;
  transition: all 0.2s ease;
}

.task-tick:hover {
  background-color: rgba(0, 34, 68, 0.1);
  color: #002244;
}

.task-tick.completed {
  color: white;
  background-color: #002244;
}

.task-tick svg {
  font-size: 16px;
}

.task-tick-container {
  position: relative;
  display: inline-block;
  padding-left: 1.5rem;
}

.task-tick-container:hover::before {
  content: "Mark as complete";
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  bottom: 100%;
  left: 80%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 100000;
}

.task-title {
  flex: 1;
}

.reservation-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 1.25rem;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
}

.reservation-filters select {
    width: 200px;
    padding:10px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    box-shadow: none;
}

.date-filter-text {
    font-size: 1rem;
}

.reservation-filters button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: #002244;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    color: white;
    cursor: pointer;
}

.reservation-container h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.reservation-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 1.25rem;
}

.reservation-filters button {
  margin-top:auto;
}

.reservation-filters select {
  padding: 0.5rem;
  border-radius: 0.375rem;
  border: 1px solid #d1d5db;
  min-width: 200px;
  background-color: white;
  height: 40px;
}

.date-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.check-in-period, .check-out-period, .building-filter, .unit-filter {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.date-filter-text, .building-filter-text, .unit-filter-text {
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b5563;
}

.reservation-filters input[type="date"] {
  padding: 0.5rem;
  border-radius: 0.375rem;
  border: 1px solid #d1d5db;
  height: 40px;
  min-width: 160px;
}

.search-input-todo {
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.task-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.task-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.task-item {
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e7eb;
  width:100%;
}

.task-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width:100%;
}

.task-mobile-chat {
  display: flex;
  flex-direction: column;
}

.name-tag, .phone-tag, .task-tag {
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  margin-right: 1rem;
  background-color: #1e1c35;
  color: white;
}

.phone-size-todo {
  display:flex;
  width:100%;
  gap:15px;
}

.add-task-type-card {
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 12px;
  overflow: hidden;
}

.add-icon-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: white;
  margin-left: 50%;
  margin-top:10px;
  margin-bottom:10px;
}

.add-task-type-modal, .delete-confirmation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 25%;
}

.add-task-type-modal input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  height: 35px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
}

.add-task-type-modal h3 {
  font-size: medium;
  margin-bottom: 1rem;
}

.add-task-type-btn-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: flex-end;
}

.cancel-button-todo {
  padding: 0.5rem 1.25rem;
  border: none;
  border-radius: 10px;
  color: white;
  transition: all 0.5s ease;
  background-color: #ef4444;
}

.add-button-todo {
  padding: 0.5rem 1.25rem;
  border: none;
  border-radius: 10px;
  color: white;
  transition: all 0.5s ease;
  background-color: #002244;
}

.add-task-type-btn-container button:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.no-tasks-message {
  display:flex;
  justify-content: space-between;
}

.no-tasks-message button {
  padding: 0.5rem 1.25rem;
  border: none;
  border-radius: 10px;
  color: white;
  transition: all 0.5s ease;
  background-color: #ef4444;
}

.navigate-inbox-btn {
  margin-left: 10px;
  background-color: #002244;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 3px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  font-size: 12px;
}

.navigate-inbox-btn-mobile {
  margin-left: 20px;
  background-color: #fafafa;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 3px 8px;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  font-size: 15px;
  margin-right:8px;
  margin-top:10px;
}

.phone-number-container:hover::before {
  content: "Chat";
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  top: -30px;  /* Position above the element */
  left: 50%;   /* Center horizontally */
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 100000;
  pointer-events: none;  /* Ensures the tooltip doesn't interfere with clicks */
}

.navigate-inbox-btn:hover {
  background-color: #003366;
}

.task-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
}

.task-title-row-name {
  display:flex;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.task-categories-container {
  max-height: 65dvh; 
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #fff;
}

@media (max-width:1200px) {
    .task-container {
        display:block;
    }

    .task-type-grid {
        padding-right:150px;
    }

    .task-list-details {
        margin-top: 1.5rem;
        margin-left: 1rem;
    }

    .check-in-period, .check-out-period {
        display: flex;
        gap: 0.2rem;
        align-items:flex-start;
        flex-direction: column;
    }
}

@media (max-width:1030px) {
    .task-type-grid {
        padding-right:100px;
    }

    .reservation-filters select {
        width: 150px;
    }
}

@media (max-width:867px) {
    .task-list-header {
        max-width: 100%;
    }

}


@media (max-width:768px) {
  .todo-container{
    margin-left:0;
  }

  .building-filter, .unit-filter {
    width: 100%;
  }

    .todolist-container {
        padding-top: 2rem; 
        padding-right: 20px;
        padding-left: 2rem;
    }

    .todolist-container h1 {
        padding-left: 0rem;
    }

    .task-type-grid {
        width:100%;
        display:block;
    }

    .task-type-card {
        margin-bottom: 10px;
    }

    .task-list-details {
        margin-left:0;
    }

    .task-list-details h2 {
        margin-bottom: 0;
    }

    .delete-task-type-btn {
        margin-bottom: 0;
    }

    .reservation-container {
        padding-left:2rem;
        padding-bottom: 5rem;
        padding-right: 2rem;
    }

    .delete-task-modal {
        width: 50%;
    }

    .reservation-filters {
      display:flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .reservation-filters select {
        width: 100%;
    }

    .navigate-inbox-btn {
      display: none;
    }
    
    .navigate-inbox-btn-mobile {
      display: flex;
    }
}

@media (max-width:637px) {
    .task-type-grid {
        padding-right:50px;
    }
}

@media (max-width:469px) {
  .add-task-container {
    display:flex;
    flex-direction: column;
  }

  .name-value {
    text-align: left;
    align-items: center;
  }

  .name-tag-todo {
    text-align: left;
    padding-top:10px;
  }

  .task-title-row {
    display: flex;
    text-align: left;
  }

  .name-tag {
    margin-top: 1.5rem;
  }
}

@media (max-width:425px) {
    .task-type-grid{
        max-height: calc(100vh - 25rem);
    }

    .delete-task-modal {
        width: 75%;
    }

    .section-content {
        padding: 8px;
    }
}

.download-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.download-button:hover {
  background-color: #45a049;
}
