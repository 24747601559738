.settings-account-main {
    padding: 5rem 4rem 0 27rem;
    height: 100vh;
}

.settings-account-main hr {
    margin: 2rem 0;
}

.settings-notifications-header {
    display: flex;
    justify-content: space-between;
}

.settings-add-user-btn {
    padding: 0.5rem 1.5rem;
    border-radius: 10px;
    border: none;
    color: white;
    background-color: #002244;
    transition: all 0.5s ease;
}

.settings-add-user-btn:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.settings-no-account {
    display: flex;
    margin-top: 15rem;
    margin-left: 15rem;
    color: #2f2f2fa4
}

.settings-no-account span {
    margin-left: 3rem;
}

/* Modal */
.account-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.account-modal-content {
    background: #FFFFFF;
    padding: 2rem;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.account-modal-content .modal-title {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1.5px solid black;
    padding: 1rem 1.5rem 2rem 1.5rem;
    border-radius: 15px;
    box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease;
}

.modal-backbtn {
    margin-right: 1rem;
    cursor: pointer;
}

.modal-header h2 {
    font-size: 1.25rem;
    font-weight: bold;
}

.modal-title h2 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.settings-account-select {
    margin-bottom: 1rem;
}

.modal-select {
    width: 100%;
}

.settings-account-details {
    margin-top: 1rem;
}

.modal-inline {
    margin-bottom: 1rem;
}

.modal-inline label {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 1rem;
}

.modal-inline input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #D1D5DB;
    border-radius: 5px;
    font-size: 1rem;
}

/* Password visibility toggle */
.modal-inline img {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.secondary-account-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
/* Create User button */
.settings-account-btn {
    display: flex;
    justify-content: end;
}

.settings-account-btn button {
    font-weight: 500;
    border: none;
    border-radius: 15px;
    background-color: #002244;
    color: white;
    padding: 0.75rem 2rem;
    transition: all 0.5s ease;
}

.settings-account-btn button:hover {
    cursor: pointer;
    transform: scale(1.02);
}

@media (max-width:867px) {
    .settings-account-main{
        padding:2rem 2rem 0 2rem;
    }

    .account-modal-content{
        overflow-y: auto;
        width:80%;
        height: 70vh;
        padding:20px;
    }
}

@media (max-width:768px) {
    .settings-add-user-btn {
        display:none;
    }

    .settings-notifications-header {
        display:flex;
        justify-content: flex-start;
    }

    .settings-notifications-header h1 {
        font-size: 1.5rem;
    }

    .notification-settings-content{
        margin-top: 15px;
    }
}