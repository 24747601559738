.activate-main {
    min-height: 100vh;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.activate-content {
    background-color: white;
    padding: 2rem 5rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
}

.activate-content h1 {
    font-size: 2rem;
}

.activate-content p {
    color: #656565;
    margin-top: 1rem;

}

.activate-content button {
    margin-top: 3rem;
    font-size: 1rem;
    width: 30vw;
    height: 50px;
    border: none;
    background: #003870;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.activate-content button:hover {
    transform: scale(1.01);
    box-shadow: 5px 5px 20px #6565652e;
}