html, body {
    max-width: 100%;
    overflow-x: hidden;
}

.content {
    padding: 10rem 10rem 5rem 10rem ;
    display: flex;
    gap: 5rem;
    justify-content: center;
    max-width: 1920px; 
    margin: auto; 
}

.index-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Main text section */
.main_text {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 75%;
}

.main_text h1 {
    font-size: clamp(2rem, 5vw, 3.3rem);
    margin-bottom: 3rem;
}

.generate-text {
    color: #0076ec;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}
.blink-cursor {
    font-weight: 300;
    color:rgba(0, 0, 0, 0.3);
    animation: blink 0.5s infinite;
}

.main_text p {
    font-size: clamp(1rem, 1.5vw, 1.25rem);
    font-weight: medium;
    color: rgba(58, 58, 58, 0.7);
    letter-spacing: 0.05rem;
    line-height: 2rem;
    text-align: center;
}

.company-text {
    position: relative;
    display: inline-block;
    color: #000; /* Adjust text color as needed */
    font-weight: bold;
    padding: 0 5px;
}
.company-text::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background-image: url('../../assets/highlight_background.png'); /* Replace with your actual image URL */
    background-size: cover;
    background-position: center;
    opacity: 1; /* Adjust for desired visibility */
    z-index: -1;
    border-radius: 5px; /* Optional: rounds the corners of the highlight */
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.1));
}

.main_text button {
    margin-top: 3rem;
    padding: 1rem 2rem;
    background-color: white;
    border-radius: 50px;
    border: 1px solid #002244;
    font-size: 0.85rem;
    color: rgb(0, 0, 0);
    letter-spacing: 0.05rem;
    font-weight: bold;
    transition: all 0.5s ease;
    cursor: pointer;
}

.main_text button:hover {
    background-color: #002244;
    color: white
}

/* Product section */
.product-section{
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    min-height: 65dvh;
    width: 80%;
    border-radius: 10px;
}

.product-header {
    font-size: clamp(0.75rem, 2vw, 1.25rem);
    padding: 2rem 5%;
    width: 100%;
    display: flex;
    align-items: center;
    height: 20%;
    gap: 2rem;
    border-bottom: 1px solid black;
}

.product-header img {
    background-color: black;
    border-radius: 50%;
}

.product-name {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.product-chat {
    padding: 2rem 5%;
    margin-bottom: auto;
    height: 55dvh;
    max-height: 55dvh;
    overflow-y: scroll;
}

.product-chat img {
    background-color: black;
    border-radius: 50%;
}

.bot-btn {
    padding: 1rem;
    display: block;
    margin-bottom: 0.5rem;
    border: 1px solid #002244;
    border-radius: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;
}

.bot-btn:hover {
    transform: scale(1.01);
    background-color: #00366bcd;
    color: white;
}

.message-out .bot-message, .message-out .bot-btn, .message-out .profile-pic{
    opacity: 1;
}

.message-invisible .bot-message, .message-invisible .bot-btn, .message-invisible .profile-pic{
    opacity: 0;
}

.user-message-container, .bot-message-container {
    margin: 1rem 0;
    display: flex;
    align-items: flex-start;
}

.user-message-container {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-self: flex-end;
    width: 100%;
}

.user-message {
    color: white;
    background-color: #00b94adb;
    text-align: left;
    align-self: flex-end;
    margin-left: auto;
    border-radius: 15px 15px 0px 15px;
    margin-right: 20px;
    padding: 15px;
}

.user-message, .bot-message {
    padding: 1rem;
    line-height: 1.5rem;
    max-width: 80%;
    position: relative;
}

.profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    bottom: 0px;
    left: -20px;
}

.bot-message {
    background-color: #002244;
    border-radius: 0px 15px 15px 15px;
    color: white;
    text-align: left;
    align-self: flex-start;
    margin-left: 1rem;
    transition: all 1s ease;
}

.product-options {
    margin-left: 3rem;
}

.product-input {
    padding: 1rem 2rem 0rem 5%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.product-input .input-container input {
    width: 70dvw !important;
}

.product-input .send-button {
    background-color: transparent;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    color: #1e1c35;
    transition: all 0.5s ease;
}

.product-input button:hover {
    background-color: white;
    transform: scale(1.01);
}



/* Testimonial section */

.testimonial-section {
    height:100%;
    margin-top: 3rem;
    width: 30%;
}

.testimonial-section p {
    font-size: clamp(1rem, 1.5vw, 1.1rem);
    font-weight: 300;
    color: rgba(58, 58, 58, 0.7);
    letter-spacing: 0.02rem;
    line-height: 2rem;
    text-align: center;
}

.companies-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: 2rem;
}

.companies {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    background-color: '#f0f0f0';
    animation: scroll 30s linear infinite;
    width: fit-content;
    margin-bottom: 50px;
}

.companies-wrapper::before,
.companies-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100px;
    height: 100%;
    z-index: 2;
    pointer-events: none;
}

.companies-wrapper::before {
    left: 0;
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}

.companies-wrapper::after {
    right: 0;
    background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}

@keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-52%);
    }
}


.companies img {
    filter: grayscale(100%);
    border: 1.5px solid rgba(0, 0, 0, 0.5);
    display: block;
    border-radius: 50%;
    margin-left: 2rem;
    max-width: 70px;
    transition: all 0.5s ease
}

.companies img:hover {
    filter: grayscale(0);  
    cursor: pointer;
    transform: scale(1.05);
    border: 1.5px solid rgb(0, 0, 0);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}


.nametag {
    position: relative;
    bottom: -0px;
    left: 70%;
    transform: translateX(-50%) scale(0);
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
    opacity: 0;
    transition: all 0.3s ease;
    display:flex;
    justify-content: center;
}

.logo-wrapper:hover .nametag {
    transform: translateX(-50%) scale(1);
    opacity: 1;
    bottom: -5px;
}


/* Bento box section */
.bento-box-section {
    max-width: 1920px; 
    margin: 2rem auto; 
    margin-bottom: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bento-box-title h1 {
    width: 100%;
    font-size:clamp(1.5rem, 5vw, 2rem);
    padding: 1rem 0rem 2rem 0rem;
    line-height: 2rem;
    text-align: center;
}

.bento-box-title span {
    color: #0076ec;
}

.bento-box-title p {
    font-size: clamp(0.85rem, 1.5vw, 1rem);
    font-weight: 300;
    color: rgba(58, 58, 58, 0.8);
    letter-spacing: 0.05rem;
    line-height: 2rem;
    text-align: center;
    margin-bottom: 2rem;
}

/* Features grid styles */
.features {
    background: #fff;
}

.feature-item {
    grid-area: one;
}  

.feature-item1 {
    grid-area: two;
} 

.feature-item2 {
    grid-area: three;
}

.feature-item3 {
    grid-area: four;
} 

.feature-item4 {
    grid-area: five;
} 

.feature-item5 {
    grid-area: six;
}  

.features-grid {
    display: grid;
    gap: 1rem;
    grid-template-areas:
        'one'
        'two'
        'three'
        'four'
        'five'
        'six';
    padding-block: 2rem;
    margin-inline: auto;
    grid-template-columns: 180px 250px 180px 200px 200px;
    grid-template-rows: 150px 150px 180px;
    max-width: 1100px;
}

.feature-item {
    background: #002244 !important;
    display: flex;
    align-items: center; 
    justify-content: center;
}

.features-grid div {
    z-index: 101;
    background-color: white;
    border: 1px solid #000000;
    text-align: center;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    transition: all 0.5s ease; 
}

.features-grid div:hover {
    cursor: pointer;
    transform: scale(1.01);
}

.features-grid h1 {
    color: rgb(255, 255, 255);
    font-size: 1.8rem;
    letter-spacing: 0.4rem;
}

.features-grid h3 {
    font-size: 3rem;
    margin-top: 20%;
}

.features-grid p {
    font-size: 1rem;
    margin-top: 2%;
}

.features-grid h2 {
    text-align: left;
    font-size: 2rem;
    margin-top: 5%;
    margin-left: 5%;
}

.feature-item2 h5 + .top {
    display: flex;
    align-items: center; 
    justify-content: center;
}

.top {
    margin-top: -35%;
    margin-left: 70%;
}

.bottom {
    margin-top: 6.5rem;
}

.features-grid h5 {
    text-align: left;
    font-size: 2rem;
    margin-top: 10%;
    margin-left: 10%;
}

.feature-item3 img {
    margin-top: 1rem;
}

.feature-item4 {
    background: #002244 !important;
}

.features-grid h4 {
    color: rgb(255, 255, 255);
    font-size: 3rem;
    margin-top: 20%;
}

.features-grid p1 {
    color: rgb(255, 255, 255);
    font-size: 1rem;
    margin-top: 30%;
}

.features-grid h6 {
    text-align: left;
    font-size: 1.8rem;
    margin-top: 3%;
    margin-left: 5%;
}

.feature-item5 {
    background-image: radial-gradient(circle, #ccc 1px, transparent 1px);
    background-size: 17px 17px;
}

.feature-item3 img {
    width:27rem;
}

.feature-item5 img {
    width:27rem;
}




/* Pricing section */
.pricing-section {
    z-index: 101;
    margin-bottom: 5rem;
}

.pricing-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gradient-text {
    background: rgb(131,58,180);
    background-image: linear-gradient(90deg, #3aa9ff, #00ff00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pricing-header p {
    font-size: clamp(0.85rem, 1.5vw, 1rem);
    font-weight: 300;
    color: rgba(58, 58, 58, 0.8);
    letter-spacing: 0.05rem;
    line-height: 2rem;
    text-align: center;
}

.pricing-header h1 {
    width: 50%;
    font-size:clamp(1.5rem, 5vw, 2rem);
    padding: 1rem 0rem 2rem 0rem;
    line-height: 3rem;
    text-align: center;

    background-image: linear-gradient(90deg, #3aa9ff, #00ff00);
    background-repeat: no-repeat;
    background-position: 0 80%;
    background-size: 100% 2px;
}

.pricing-body {
    padding: 0 1rem;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.pricing-body > div {
    padding: 3rem 2rem;
    background-color: white;
    border-radius: 20px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: all 0.5s ease;
}

.pricing-body > div:hover {
    transform: scale(1.03);
    box-shadow: 0 0 4px rgba(0,0,0, 0.3)
}

.pricing-content-header p{
    font-weight: 500;
    font-size: 1rem;
    color:#00000073;
}

/* Special pricing */
.pricing-medium {
    background-color: #001223 !important;
    padding: 2.3rem 2rem !important;
    color: white !important;
}
.pricing-medium h1 {
    color: rgba(255, 255, 255, 0.5) !important;
}
.pricing-medium span {
    color: white !important;
}
.pricing-medium .tier p {
    color: rgba(255, 255, 255, 0.6) !important;
}
.pricing-medium button {
    background-color: white !important;
    color: black !important;   
}
.pricing-medium input:checked + .slider-btn {
    background-color: #ffffff60;
}

.tier {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.popular-tag {
    font-size: 0.75rem;
    border-radius: 15px;
    padding: 0.5rem;
    background: linear-gradient(45deg, #20f8ff, #00ff55);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;

}
.popular-tag::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 15px;
    border: 1px solid transparent;
    background: linear-gradient(45deg, #20f8ff, #00ff55) border-box;
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}


/* Pricing content */
.price {
    font-size: 2rem;
    color: black;
    font-weight: bold;
}

.pricing-content-header h1 {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 1rem;
    color: #0000006e;
}

.pricing-content-header button {
    border: none;
    border-radius: 15px;
    background-color: #002244;
    color: white;
    font-weight: 500;
    padding: 0.75rem;
    margin-top: 2rem;
    width: 100%;
    transition: all 0.5s ease;
}

.pricing-content-header button:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.pricing-content-selection {
    margin-top: 1rem;
}
.pricing-content-selection p{
    font-weight: 500;
    font-size: 0.9rem !important;
}

.tick-icon {
    vertical-align: middle;
    font-size: 1.75rem;
    margin-right: 1rem;
}

.pricing-content-body {
    font-weight: 500;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}


/* Call to Action Section */
.call-to-action-section {
    z-index: 101;
    min-height: 60dvh;
    width: 100%;
    margin: 5rem 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0045894b;
    background: linear-gradient(0deg, #0069d223 0%, rgba(120,242,255,0.1) 10%, rgba(255,255,255,0) 100%);
}

.call-to-action-section h1 {
    font-size: clamp(2rem, 5vw, 3.3rem);
    margin-bottom: 3rem;
}

.call-to-action-section p {
    font-size: clamp(0.85rem, 2vw, 1.25rem);
    color: #00000084;
    margin-bottom: 5rem;
    text-align: center;
}

.call-to-action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    margin-bottom: 5rem;
}

.call-to-action-btn button {
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 40px;
    background-color: black;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    transition: all 0.5s ease;
}

.call-to-action-btn button:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.call-to-action-btn h3{
    transition: all 0.5s ease;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    padding-bottom: 0.5rem;
}

.call-to-action-btn h3:hover {
    cursor: pointer;
    border-bottom: 2px solid black;
}

.call-to-action-right {
    vertical-align: middle;
    margin-left: 0.5rem;
}



/* Slider css */
.pricing-toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    gap: 15px;
    margin-bottom: 20px;
}

.pricing-toggle-container span {
    color: #888;
    transition: color 0.3s ease;
}

.pricing-toggle-container span.active {
    color: #000;
    font-weight: bold;
}

.pricing-toggle-container .discount-tag {
    background-color: #4CAF50;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    margin-left: 10px;
    font-size: 0.8rem;
}

/* Toggle Switch Styles */
.pricing-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.pricing-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d8d8d8bf;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 1.75rem;
    width: 1.75rem;
    left: 0.2rem;
    bottom: 0.2rem;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: #002244;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}




/*  Mobile responsive codes */
@media (min-width: 1080px) {
    .features-grid {
        grid-template-areas: 
        'one one two three three'
        'four four five three three'
        'four four six six six';
    }
}

@media (max-width: 1200px) {
    .features-grid {
        grid-template-areas: 
            'one six'
            'three two'
            'four five';
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        padding: 0.5rem;
    }

    .features-grid div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        min-height: 10rem;
        padding: 1rem;
    }

    .features-grid h1, .features-grid h3, .features-grid h5, .features-grid h6, .features-grid h2, .features-grid h4, .features-grid p1 {
        font-size: 1.2rem;
        margin-top: 0;
    }

    .feature-item3 img, .feature-item5 img {
        width: 100%;
    }

    .features-grid h5 {
        font-size: 1.75rem;
        padding: 1rem;
        margin-left: 6rem;
        margin-bottom: 7rem;
    }

    .top {
        margin-top: -15rem;
    }

    .bottom {
        margin-top: 5rem;
    }

    .testimonial-section {
        width:40%;
    }
}



/* Tablet size  */
@media (max-width: 860px) {
    .product-input .input-container input {
        width: 60dvw !important;
    }

    .main_text {
        width: 100%;
    }

    .content {
        padding: 10rem 5%;
        flex-direction: column;
        gap: 2rem;
    }

    .main_text h1 {
        font-size: 2rem;
        padding: 1rem 0rem 2rem 0rem;
    }

    .main_text p {
        font-size: 1rem;
        width: 100%;
    }

    .main_text button {
        width: 100%;
        font-size: 1rem;
    }

    .promo_chat {
        padding-top: 2rem;
        padding-right: 0;
    }

    .subcontent {
        padding: 0 5%;
    }

    .subtitle h1 {
        font-size: 2.5rem;
    }

    .subtitle p {
        font-size: 1rem;
        text-align: justify;
    }

    .features-grid {
        grid-template-areas: 
            'one'
            'two'
            'three'
            'four'
            'five'
            'six';
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .features-grid div {
        padding: 1rem;
    }

    .features-grid h1, .features-grid h3, .features-grid h5, .features-grid h6, .features-grid h2, .features-grid h4, .features-grid p1 {
        font-size: 1.2rem;
        margin-top: 0;
    }

    .feature-item3 img, .feature-item5 img {
        width: 100%;
    }

    .features-grid h5 {
        font-size: 1.75rem;
        padding: 1rem;
        margin-left: 2rem;
        margin-bottom: 7rem;
    }

    .bottom {
        margin-top: 5rem;
    }

    .testimonial-section {
        width:50%;
    }
}

/* Mobile size */
@media (max-width:520px) {

    .content {
        width: 100%;
        padding: 10rem 0;
        flex-direction: column;
        gap: 2rem;
    }

    .main_text {
        width: 100%;
    }

    .subcontent {
        margin-bottom: 3rem;
    }
    
    .features-grid {
        display:block;
    }

    .feature-hover {
        margin-bottom: 1rem;
    }

    .features-grid h5 {
        margin-left: 0;
    }

    .bottom {
        padding-right: 10px;
    }

    .feature-item5 img {
        margin-top: 10px;
    }

    .testimonial-section {
        width:80%;
    }

}
